import { DataField } from '@models/settings/data-field.model';

/**
 * Merge elements of `fields` into `baseFields` when there is an ID match
 */
export const mergeDataFieldsById = (baseFields: DataField[], fields: DataField[]): DataField[] => {
    return baseFields.map((baseField) => {
        const matchedField = fields.find((field) => field.id === baseField.id);

        return matchedField ?? baseField;
    });
};
