import { Directive, Signal } from '@angular/core';
import { SpecialInformationBarService } from '../../special-information-bar.service';
import { DisplayConditions } from '../../types';

/**
 * The base class for the various information bars.
 * This class automatically pulls in the display conditions from the service and sets in a signal that all derived classes can read
 */
@Directive()
export abstract class BaseInformationBar {
    get displayConditions(): Signal<DisplayConditions | undefined> {
        return this.specialInformationBarService.displayConditionsSignal;
    }

    /**
     * Logic for whether the banner should be displayed or not. Typically determined via variables found in displayConditions
     */
    abstract shouldDisplay: Signal<boolean>;

    constructor(private specialInformationBarService: SpecialInformationBarService) {}
}
