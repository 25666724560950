@if (shouldDisplay()) {
    <mat-icon svgIcon="warning" />
    <div>
        {{
            'payroll.kyc-kyb.banner.message.0' | translate: { scope: ('payroll.kyc-kyb.scopes.' + scope() | translate) }
        }}
        <a [routerLink]="linkToPreRedirect">{{ 'payroll.kyc-kyb.banner.message.1' | translate }}</a
        >{{ 'payroll.kyc-kyb.banner.message.2' | translate }}
    </div>
}
