import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, BaseRouteReuseStrategy } from '@angular/router';
import { ModelUnion } from '@app/models/core/base.model';

@Injectable()
export class RouteReuseStrategy extends BaseRouteReuseStrategy {
    shouldReuseRoute(future: ActivatedRouteSnapshot, current: ActivatedRouteSnapshot): boolean {
        const currentDataModel: Map<string, ModelUnion> = current.data?.models?.map;
        const futureDataModel: Map<string, ModelUnion> = future.data?.models?.map;

        // If we're changing the route from one with a resolved data model to a new route with a resolved data model
        if (currentDataModel?.size && futureDataModel?.size) {
            // Only reuse the components if the models are the same (equal ID) and same path location
            // ie. employees/directory/163/benefits -> employees/directory/163/time-off should not reuse
            return (
                Array.from(currentDataModel.values())[0]?.id === Array.from(futureDataModel.values())[0]?.id &&
                current.routeConfig?.path === future.routeConfig?.path
            );
        }

        // Use the default route reuse strategy
        return super.shouldReuseRoute(future, current);
    }
}
