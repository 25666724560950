<form #form="ngForm" autocomplete="off">
    <!-- Employment related information -->
    <ui-card>
        <h2 class="ui header">{{ 'forms.setup-employee.directDepositHeader' | translate }}</h2>

        <!-- SIN Card -->
        <div class="app-grid notranslate">
            <app-form-data-fields
                #employmentInformationDataFields
                columnClass="columns-6"
                [employee]="employee"
                [dataFieldGroup]="defaultGroupFieldNames.employment_information"
                [dataFields]="defaultGroupCustomDataFields"
                [(dataValues)]="defaultGroupCustomDataValues"
                [emitFormValidation]="true"
                [isDefaultGroup]="true"
            ></app-form-data-fields>

            <mat-form-field class="columns-4 small-columns-12">
                <mat-label>{{ 'forms.setup-employee.sinLabel' | translate }}</mat-label>
                <input
                    matInput
                    type="text"
                    placeholder="123456789"
                    #sin="ngModel"
                    maxlength="9"
                    pattern="[0-9]{9,9}"
                    [required]="isSinRequired"
                    sinValidator
                    name="sin"
                    [textMask]="sinTextMask"
                    [luhnRequired]="isPayrollSyncEnabled"
                    [isValidationRequired]="isSinRequired"
                    [(ngModel)]="formData.sin"
                />
                <mat-hint>{{ 'forms.setup-employee.sinHelperText' | translate }}</mat-hint>
                <mat-error *ngIf="sin.invalid">{{ sin.errors | formErrors }}</mat-error>
            </mat-form-field>

            <app-form-data-fields
                #bankAccountDataFields
                columnClass="columns-6"
                [employee]="employee"
                [dataFieldGroup]="defaultGroupFieldNames.bank_account"
                [dataFields]="defaultGroupCustomDataFields"
                [(dataValues)]="defaultGroupCustomDataValues"
                [emitFormValidation]="true"
                [isDefaultGroup]="true"
            ></app-form-data-fields>
        </div>

        <!-- Banking Info -->
        <ng-container *ngIf="bankingInformationOnboarding">
            <div class="app-grid">
                <mat-form-field class="columns-4 small-columns-12">
                    <mat-label>{{ 'forms.setup-employee.bankInstituteNumberLabel' | translate }}</mat-label>
                    <input
                        matInput
                        placeholder="002"
                        #bankInstitution="ngModel"
                        name="bankInstitution"
                        [required]="bankingInformationRequiredByState"
                        pattern="[0-9]{3}"
                        maxlength="3"
                        [(ngModel)]="formData.bankInstitution"
                        (ngModelChange)="onBankingInformationChange()"
                        [textMask]="bankInstitutionMask"
                    />
                    <mat-hint>{{ 'forms.setup-employee.bankInstituteNumberHelperText' | translate }}</mat-hint>
                    <mat-error *ngIf="bankInstitution.invalid">{{ bankInstitution.errors | formErrors }}</mat-error>
                </mat-form-field>
                <mat-form-field class="columns-4 small-columns-12">
                    <mat-label>{{ 'forms.setup-employee.bankTransitNumberLabel' | translate }}</mat-label>
                    <input
                        matInput
                        type="text"
                        placeholder="65834"
                        #bankBranch="ngModel"
                        name="bankBranch"
                        [required]="bankingInformationRequiredByState"
                        pattern="[0-9]{5}"
                        maxlength="5"
                        [(ngModel)]="formData.bankBranch"
                        (ngModelChange)="onBankingInformationChange()"
                        [textMask]="bankTransitMask"
                    />
                    <mat-hint>{{ 'forms.setup-employee.bankTransitNumberHelperText' | translate }}</mat-hint>
                    <mat-error *ngIf="bankBranch.invalid">{{ bankBranch.errors | formErrors }}</mat-error>
                </mat-form-field>

                <mat-form-field class="columns-4 small-columns-12">
                    <mat-label>{{ 'forms.setup-employee.bankAccountNumberLabel' | translate }}</mat-label>
                    <input
                        matInput
                        type="text"
                        placeholder="5269443"
                        #bankAccount="ngModel"
                        name="bankAccount"
                        [required]="bankingInformationRequiredByState"
                        minlength="5"
                        maxlength="12"
                        pattern="[0-9]{5,12}"
                        [(ngModel)]="formData.bankAccount"
                        (ngModelChange)="onBankingInformationChange()"
                        [textMask]="bankAccountMask"
                    />
                    <mat-hint>{{ 'forms.setup-employee.bankAccountNumberHelperText' | translate }}</mat-hint>
                    <mat-error *ngIf="bankAccount.invalid">{{ bankAccount.errors | formErrors }}</mat-error>
                </mat-form-field>
            </div>
        </ng-container>
    </ui-card>

    <!-- Emergency contact information -->
    <ui-card>
        <h2 class="ui header">{{ 'forms.setup-employee.emergencyContactsHeader' | translate }}</h2>

        <!-- Primary contact -->
        <div class="app-grid">
            <mat-form-field class="columns-4 small-columns-12">
                <mat-label>{{ 'forms.setup-employee.primaryContactNameLabel' | translate }}</mat-label>
                <input
                    matInput
                    type="text"
                    placeholder="{{ 'forms.setup-employee.primaryContactNamePlaceholder' | translate }}"
                    #ecPrimaryName="ngModel"
                    name="ecPrimaryName"
                    [(ngModel)]="formData.ecPrimaryName"
                />
                <mat-error *ngIf="ecPrimaryName.invalid">{{ ecPrimaryName.errors | formErrors }}</mat-error>
            </mat-form-field>
            <mat-form-field class="columns-4 small-columns-12">
                <mat-label>{{ 'forms.setup-employee.primaryContactRelationLabel' | translate }}</mat-label>
                <input
                    matInput
                    type="text"
                    placeholder="{{ 'forms.setup-employee.primaryContactRelationPlaceholder' | translate }}"
                    #ecPrimaryRelation="ngModel"
                    name="ecPrimaryRelation"
                    [(ngModel)]="formData.ecPrimaryRelation"
                />
                <mat-error *ngIf="ecPrimaryRelation.invalid">{{ ecPrimaryRelation.errors }}</mat-error>
            </mat-form-field>

            <mat-form-field class="columns-4 small-columns-12">
                <mat-label>{{ 'forms.setup-employee.primaryContactPhoneLabel' | translate }}</mat-label>
                <input
                    matInput
                    type="text"
                    placeholder="416-405-4039"
                    #ecPrimaryPhone="ngModel"
                    name="ecPrimaryPhone"
                    [(ngModel)]="formData.ecPrimaryPhone"
                />
                <mat-error *ngIf="ecPrimaryPhone.invalid">{{ ecPrimaryPhone.errors }}</mat-error>
            </mat-form-field>
        </div>

        <!-- Secondary contact -->
        <div class="app-grid">
            <mat-form-field class="columns-4 small-columns-12">
                <mat-label>{{ 'forms.setup-employee.secondaryContactNameLabel' | translate }}</mat-label>
                <input
                    matInput
                    type="text"
                    placeholder="{{ 'forms.setup-employee.secondaryContactNamePlaceholder' | translate }}"
                    #ecSecondaryName="ngModel"
                    name="ecSecondaryName"
                    [(ngModel)]="formData.ecSecondaryName"
                />
                <mat-error *ngIf="ecSecondaryName.invalid">{{ ecSecondaryName.errors }}</mat-error>
            </mat-form-field>
            <mat-form-field class="columns-4 small-columns-12">
                <mat-label>{{ 'forms.setup-employee.secondaryContactRelationLabel' | translate }}</mat-label>
                <input
                    matInput
                    type="text"
                    placeholder="{{ 'forms.setup-employee.secondaryContactRelationPlaceholder' | translate }}"
                    #ecSecondaryRelation="ngModel"
                    name="ecSecondaryRelation"
                    [(ngModel)]="formData.ecSecondaryRelation"
                />
                <mat-error *ngIf="ecSecondaryRelation.invalid">{{ ecSecondaryRelation.errors }}</mat-error>
            </mat-form-field>
            <mat-form-field class="columns-4 small-columns-12">
                <mat-label>{{ 'forms.setup-employee.secondaryContactPhoneLabel' | translate }}</mat-label>
                <input
                    matInput
                    type="text"
                    placeholder="416-405-4039"
                    #ecSecondaryPhone="ngModel"
                    name="ecSecondaryPhone"
                    [(ngModel)]="formData.ecSecondaryPhone"
                />
                <mat-error *ngIf="ecSecondaryPhone.invalid">{{ ecSecondaryPhone.errors }}</mat-error>
            </mat-form-field>
        </div>

        <app-form-data-fields
            #emergencyContactsDataFields
            columnClass="columns-6"
            [employee]="employee"
            [dataFieldGroup]="defaultGroupFieldNames.emergency_contacts"
            [dataFields]="defaultGroupCustomDataFields"
            [(dataValues)]="defaultGroupCustomDataValues"
            [emitFormValidation]="true"
            [isDefaultGroup]="true"
        ></app-form-data-fields>
    </ui-card>

    <!-- Custom Fields -->
    <ng-container *ngIf="customDataFieldGroups.length > 0">
        <ui-card *ngFor="let group of customDataFieldGroups">
            <app-segment-header [title]="group.name"></app-segment-header>
            <app-form-data-fields
                #editDataFieldsForm
                [employee]="employee"
                [dataFieldGroup]="group.name"
                [dataFields]="dataFieldsService.getDataFieldsByGroup(group.id, customDataFields)"
                [(dataValues)]="defaultGroupCustomDataValues"
                [columnClass]="'columns-6'"
                [emitFormValidation]="true"
            ></app-form-data-fields>
        </ui-card>
    </ng-container>
</form>
