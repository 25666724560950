import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { environment } from '@env/environment';

import { Invoice, InvoiceLineItem } from '@models/settings/billing/invoice.model';
import { Subscription } from '@models/settings/billing/subscription.model';

declare let Stripe: any;
declare let window: any;

@Component({
    selector: 'app-form-setup-payment',
    templateUrl: './setup-payment.form.html',
    styleUrls: ['./setup-payment.style.scss'],
})
export class SetupPaymentForm {
    @ViewChild('form', { static: true }) form: NgForm;

    invoices: Invoice[] = [];
    invoiceLineItems: InvoiceLineItem[] = [];

    subscription: Subscription | null;

    isLoading = false;

    @Input() formData: any = {};

    @Output() valid: EventEmitter<any> = new EventEmitter<any>();

    formValid = false;

    stripe: any;
    stripe_elements: any;
    card_element: any;
    card: any = {};

    constructor() {
        window.skipPayment = () => {
            this.formData['token'] = 'tok_visa';
            this.formValid = true;
            this.valid.emit({ valid: this.formValid, data: this.formData });
        };
    }

    get monthlySubtotal(): number {
        if (!this.invoiceLineItems.length) {
            return 0;
        }

        return this.invoiceLineItems.filter(({ plan }) => plan).reduce((total, { amount }) => total + amount, 0);
    }

    get dueNowSubtotal(): number {
        if (!this.invoiceLineItems.length) {
            return 0;
        }

        return this.invoiceLineItems.filter(({ plan }) => !plan).reduce((total, { amount }) => total + amount, 0);
    }

    get percentDiscount(): number {
        if (!this.subscription.discount?.coupon?.percentOff) {
            return 0;
        }

        return this.subscription.discount.coupon.percentOff;
    }

    get dollarDiscount(): number {
        if (!this.subscription.discount?.coupon?.amountOff) {
            return 0;
        }

        return this.subscription.discount.coupon.amountOff;
    }

    createToken() {
        this.isLoading = true;
        this.stripe
            .createToken(this.card_element, {
                address_line1: this.formData['address'],
                address_city: this.formData['city'],
                address_state: this.formData['province'],
                address_zip: this.formData['postalCode'],
                address_country: this.formData['country'],
            })
            .then((result) => {
                if (result.error) {
                    // Inform the user if there was an error
                    const errorElement = document.getElementById('card-errors');
                    errorElement.textContent = result.error.message;
                    this.isLoading = false;
                } else {
                    // Send the token to your server
                    this.isLoading = false;
                    this.stripeTokenHandler(result.token);
                }
            });
    }

    ngOnInit() {
        this.isLoading = true;
        this.setupStripe();
        this.valid.emit({ valid: this.formValid, data: this.formData });

        Subscription.get().then(([subscriptions, _]) => {
            if (!subscriptions.length) {
                return;
            }

            this.subscription = subscriptions[0];
        });

        Invoice.get().then(([invoices, _]) => {
            this.invoices = invoices;
            this.invoiceLineItems = this.invoices.reduce((lineItems, { lines }) => {
                return [...lineItems, ...lines];
            }, []);
            this.isLoading = false;
        });
    }

    private setupStripe() {
        setTimeout(() => {
            this.stripe = Stripe(environment.stripeKey);
            this.stripe_elements = this.stripe.elements();
            this.card_element = this.stripe_elements.create('card', {
                hidePostalCode: true,
            });
            this.card_element.mount('#card-element');
            this.card_element.addEventListener('change', function (event) {
                const displayError = document.getElementById('card-errors');
                if (event.error) {
                    displayError.textContent = event.error.message;
                } else {
                    displayError.textContent = '';
                }
            });
        });
    }

    private stripeTokenHandler(token) {
        this.formData['token'] = token.id;
        this.formValid = true;
        this.valid.emit({ valid: this.formValid, data: this.formData });
    }
}
