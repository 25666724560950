import { Component } from '@angular/core';
import { Router } from '@angular/router';

import { AnalyticEvents } from '@app/enums';
import { AnalyticService, AuthService } from '@app/services';

@Component({
    templateUrl: 'activate.view.html',
    styleUrls: ['activate.style.scss'],
})
export class ActivateView {
    firstName?: string;
    email?: string;
    isLoading = true;

    constructor(
        private router: Router,
        private analyticsService: AnalyticService,
        private authService: AuthService
    ) {
        // The activationToken should have been received in the parent activate-router, if not, we cannot check its validity
        if (this.authService.activationToken) {
            this.authService
                .checkActivationToken(this.authService.activationToken)
                .then((res) => {
                    if (res.data.attributes.expired) {
                        throw new Error();
                    }
                    this.email = res.data.attributes.email;
                    this.firstName = res.data.attributes.firstName;
                })
                .catch(() => this.handleError())
                .finally(() => (this.isLoading = false));
        } else {
            this.handleError();
        }
    }

    nextStep(): void {
        this.analyticsService.trackEvent(AnalyticEvents.EmployeeOnboardingSignUpUsingEmailFromActivationEmail);
        this.router.navigate(['/resetPassword']);
    }

    handleError(): void {
        this.router.navigate(['activateInvitation', 'error']);
    }
}
