@if (shouldDisplay()) {
    <div class="quickstart-information">
        <!-- First line shows a message about completing quickstart -->
        <div><span class="margin-right-1">🚀</span>{{ 'self-serve.continueSettingUpAccount' | translate }}</div>

        <!-- Second line shows metrics and buttons -->
        <div class="progress-container hide-small">
            <mat-progress-bar
                class="green-fill"
                mode="determinate"
                [value]="(stepsCompleted() / totalSteps()) * 100"
            ></mat-progress-bar>
            <div class="no-wrap text-color-secondary-font">
                {{ stepsCompleted() }} / {{ totalSteps() }} {{ 'self-serve.completed' | translate }}
            </div>
        </div>
        <ui-button url="/company-onboarding" type="primary">{{ 'self-serve.continue' | translate }}</ui-button>
        <app-trial-days-remaining [trialExpiresAt]="trialExpiresAt" />
    </div>
}
