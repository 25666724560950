import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { AuthService } from '@app/services/auth.service';
import { Office } from '@app/models/company/office.model';
import { NotifyService } from '@app/services';
import { environment } from '@env/environment';
import { CompanyLogoService } from '@app/modules/settings/services';

declare let google: any;

@Component({
    selector: 'app-form-setup-company',
    templateUrl: './setup-company.form.html',
})
export class SetupCompanyForm implements OnInit, OnDestroy {
    @ViewChild('form', { static: true }) form: NgForm;
    @Input() formData: any = {};
    @Output() valid: EventEmitter<any> = new EventEmitter<any>();

    logo: File | null = null;

    private autocomplete;

    constructor(
        public auth: AuthService,
        private changeDetectionRef: ChangeDetectorRef,
        private companyLogoService: CompanyLogoService,
        private notify: NotifyService
    ) {}

    ngOnInit(): void {
        this.initAutocomplete();
        this.form.statusChanges.subscribe(() => {
            this.valid.emit({ valid: this.form.valid, data: this.formData });
        });
    }

    ngOnDestroy(): void {
        this.valid.emit({ valid: this.form.valid, data: this.formData });
    }

    async saveLogo(file: File | null): Promise<void> {
        this.logo = file;
        if (!file) {
            this.formData.logoId = null;
            return;
        }

        try {
            this.companyLogoService.store(file);
        } catch (error) {
            this.notify.error(error);
        }
    }

    private initAutocomplete(): void {
        setTimeout(() => {
            $.getScript(
                `https://maps.googleapis.com/maps/api/js?key=${environment.googleMapsApiKey}&libraries=places`,
                () => {
                    this.autocomplete = new google.maps.places.Autocomplete($('[name="address"]').get(0), {
                        types: ['address'],
                    });
                    this.autocomplete.addListener('place_changed', () => {
                        this.fillInAddress();
                    });
                }
            );
        });
    }

    private fillInAddress(): void {
        // Get the place details from the autocomplete object.
        const place = this.autocomplete.getPlace();
        if (!place) {
            return;
        }
        const address = Office.createFromGooglePlace(place);

        this.formData['address'] = address.addressLine1;
        this.formData['city'] = address.city;
        this.formData['province'] = address.province;
        this.formData['country'] = address.country;
        this.formData['postalCode'] = address.postalCode;
        this.changeDetectionRef.detectChanges();
    }
}
