import { HttpHeaders } from '@angular/common/http';

export const X_LANG_HEADER = 'X-lang';

export function appendLanguageToHeaders(headers: HttpHeaders): HttpHeaders {
    const lang = localStorage.getItem(X_LANG_HEADER);
    if (lang) {
        return headers.append(X_LANG_HEADER, lang);
    }
    return headers;
}
