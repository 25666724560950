<div class="page-container centered">
    <ui-card variant="outline">
        <h2 level="1">{{ 'employees.account-activation.onboardingLinkNotValid' | translate }}</h2>
        @if (!token) {
            <!-- No token -->
            @for (translation of 'employees.account-activation.emptyTokenError' | translate; track translation) {
                <p>{{ translation }}</p>
            }
        } @else {
            <!-- Invalid token -->
            <p>
                {{ 'employees.account-activation.activateInstruction' | translate }}
                <a [routerLink]="['/login']">{{ 'employees.account-activation.clickToLogin' | translate }}</a
                >.
            </p>
            <p>{{ 'employees.account-activation.unlockAccountInstruction' | translate }}</p>
        }
    </ui-card>
</div>
