import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { appendLanguageToHeaders } from '@app/functions/x-lang';
import { AuthService, TokenService } from '@app/services';
import { environment } from '@env/environment';
import * as Sentry from '@sentry/angular';
import { Observable, of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { SKIP_HUMI_TOKEN } from './context-tokens';
import { shouldSkipInterceptor } from './helpers/shouldSkipInterceptor';

/**
 * This interceptor is always hit after the requests have gone through the Auth0Interceptor
 * The Auth0 token should always be in memory of the token service at this point.
 * It is used to retrieve the Humi tokens that keep track of the user's role, which will be attached in an auth header on all requests.
 */
@Injectable()
export class HumiTokenInterceptor implements HttpInterceptor {
    constructor(
        private tokenService: TokenService,
        private humiAuthService: AuthService
    ) {}

    intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        if (shouldSkipInterceptor(req.url)) {
            return next.handle(req);
        }

        // Make requests point to our BE API depending on the environment
        if (req.url.indexOf('http') < 0) {
            req = this.replaceURLDomainWithAPI(req);
        }

        // Adds language header to existing headers
        req = req.clone({ headers: appendLanguageToHeaders(req.headers) });

        // Some requests are not meant to be sent with the Humi Auth token, such as login credentials (local) or password reset (new account activation)
        if (req.context.get(SKIP_HUMI_TOKEN)) {
            return next.handle(req);
        }

        // If the user is signed in and we already have their token simply add it to the request headers and move to the next interceptor
        if (this.tokenService.token) {
            return this.sendRequestWithToken(req, next);
        }

        // If we don't have the Humi tokens yet we will send the request to the BE for the token before continuing this request
        return this.humiAuthService.retrieveHumiTokens().pipe(
            catchError((error) => {
                Sentry.captureException(error, { level: 'debug', tags: { auth0: true } });
                return of(void 0);
            }),
            switchMap(() => this.sendRequestWithToken(req, next))
        );
    }
    private replaceURLDomainWithAPI(req: HttpRequest<unknown>): HttpRequest<unknown> {
        return req.clone({ url: environment.api + req.url });
    }

    /**
     * Adds the token to the header and sends the request
     */
    private sendRequestWithToken(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        // If for some reason we can't get the tokens we will continue to make the request without the auth header, likely resulting in an authentication error from the BE
        if (!this.tokenService.token) {
            Sentry.captureMessage('Unable to retrieve Humi token, continuing request without Authorization header', {
                level: 'debug',
                tags: { auth0: true },
            });
            return next.handle(req);
        }

        return next.handle(req.clone({ headers: req.headers.append('Authorization', `${this.tokenService.token}`) }));
    }
}
