import { Location } from '@angular/common';
import { Component, ElementRef } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ErrorParser } from '@app/classes';
import { FeatureFlag } from '@app/enums';
import { AuthService, NotifyService } from '@app/services';
import { FeatureService } from '@app/services/feature.service';
import { isNil } from 'lodash-es';
import { JsonApiError } from '@app/errors/json-api.error';
import { TranslatableKey } from '@app/types/translatable.type';

@Component({
    templateUrl: './store-password.template.html',
    styleUrls: ['../common.style.scss', '../common.responsive.scss', './store-password.style.scss'],
})
export class StorePasswordView {
    readonly minimumCharacterCheck = 8;

    hasLowerCase = false;
    hasUpperCase = false;
    hasNumber = false;
    hasMinimumCharacters = false;
    passwordsMatch = false;

    token: string;
    confirmPassword?: string;
    isLoading = false;
    authorized = false;
    activated = false;
    passwordStored = false;
    showPassword = false;

    password: FormControl<string | null> = new FormControl();
    isOnboarding = false;
    standardErrorFeatureFlag = false;

    constructor(
        protected auth: AuthService,
        protected route: ActivatedRoute,
        protected router: Router,
        protected notify: NotifyService,
        protected location: Location,
        protected element: ElementRef,
        private featureService: FeatureService,
        private authService: AuthService
    ) {
        if (this.authService.activationToken) {
            this.token = this.authService.activationToken;
            this.isOnboarding = true;
            this.authorized = true;
            this.focusInput();
        } else {
            this.token = this.route.snapshot.queryParams['token'];
            this.authenticateByToken();
            this.activated = true;
        }
        this.featureService.has(FeatureFlag.standardErrorResponse).then((flagValue: boolean) => {
            this.standardErrorFeatureFlag = flagValue;
        });
    }

    validatePassword(): void {
        if (this.password.value) {
            this.hasLowerCase = !!this.password.value.match(/[a-z]/);
            this.hasUpperCase = !!this.password.value.match(/[A-Z]/);
            this.hasNumber = !!this.password.value.match(/\d/);
            this.hasMinimumCharacters = this.password.value.length >= this.minimumCharacterCheck;
        } else {
            this.hasLowerCase = false;
            this.hasUpperCase = false;
            this.hasNumber = false;
            this.hasMinimumCharacters = false;
        }
        this.setControlErrors();

        if (this.hasLowerCase && this.hasUpperCase && this.hasNumber && this.hasMinimumCharacters) {
            this.passwordsMatch =
                !isNil(this.password && this.confirmPassword) && this.password.value === this.confirmPassword;
        } else {
            this.passwordsMatch = false;
        }
    }

    savePassword(): void {
        if (!this.passwordIsValid()) {
            return;
        }

        this.isLoading = true;
        this.storePassword();
    }

    toggleShowPassword(): void {
        this.showPassword = !this.showPassword;
    }

    goBack(): void {
        this.location.back();
    }

    private setControlErrors(): void {
        const hasError = [this.hasLowerCase, this.hasUpperCase, this.hasNumber, this.hasMinimumCharacters].some(
            (valid) => !valid
        );

        const errors = hasError ? { invalid: true } : null;
        this.password.setErrors(errors);
    }

    private focusInput(): void {
        // Because the view render changes based on whether or not someone is authenticated or not, we need to wrap this in a timeout
        // to make sure angular has set all the flags first before we attempt to focus the input.
        setTimeout(() => {
            this.element.nativeElement.querySelector('input').focus();
        });
    }

    private async activateInvitation(): Promise<void> {
        if (this.activated) {
            return Promise.resolve();
        }

        try {
            await this.auth.activateInvitation();
            this.activated = true;
        } catch (err) {
            this.notify.error(ErrorParser.parse(err as Record<string, unknown>));
        }
    }

    private async storePassword(): Promise<void> {
        const errorMessage: TranslatableKey = 'unableToSetPassword';

        if (!this.password.value || !this.confirmPassword) {
            this.notify.error(errorMessage);
            return;
        }

        await this.activateInvitation();

        const oAuthResponse = await this.auth
            .storePassword({
                value: this.password.value,
                valueConfirmation: this.confirmPassword,
            })
            .catch((err: JsonApiError) => {
                const errorMap = ErrorParser.parseAsMap(err.raw.error);

                if ('value' in errorMap) {
                    this.password.setErrors({ custom: { message: errorMap.value } });
                }
            })
            .finally(() => {
                this.isLoading = false;
            });

        if (!oAuthResponse) {
            this.notify.error(errorMessage);
            return;
        }

        this.passwordStored = true;
    }

    private passwordIsValid(): boolean {
        return (
            this.hasLowerCase && this.hasUpperCase && this.hasNumber && this.hasMinimumCharacters && this.passwordsMatch
        );
    }

    private authenticateByToken(): void {
        this.isLoading = true;
        this.auth
            .authenticateWithPasswordResetToken(this.token)
            .then(() => {
                this.isLoading = false;
                this.authorized = true;
                this.focusInput();
            })
            .catch((err) => {
                this.isLoading = false;
                this.authorized = false;
                if (this.standardErrorFeatureFlag) {
                    this.notify.error(ErrorParser.parseJsonApiError(err));
                } else {
                    this.notify.error(ErrorParser.parse(err));
                }
            });
    }
}
