import { Component, computed, input, InputSignal, Signal } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { differenceInCalendarDays, isFuture } from 'date-fns';

@Component({
    selector: 'app-trial-days-remaining',
    standalone: true,
    imports: [TranslateModule],
    template: `
        @if (daysRemaining() > 0) {
            <div class="days-remaining">
                🕚
                @if (daysRemaining() === 1) {
                    {{ 'self-serve.oneDayLeft' | translate }}
                } @else {
                    {{ 'self-serve.daysLeft' | translate: { daysLeft: daysRemaining() } }}
                }
            </div>
        }
    `,
    styleUrls: ['./trial-days-left.style.scss'],
})
export class SelfServeTrialDaysRemaining {
    trialExpiresAt: InputSignal<Date | null | undefined> = input.required();

    daysRemaining: Signal<number> = computed(() => {
        const trialEndDate = this.trialExpiresAt();
        return trialEndDate && isFuture(trialEndDate)
            ? Math.max(differenceInCalendarDays(trialEndDate, new Date()), 0)
            : 0;
    });
}
