import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Company } from '@company/../../models/company/company.model';

@Component({
    selector: 'app-form-setup-complete',
    templateUrl: './setup-complete.form.html',
    styleUrls: ['./setup-complete.style.scss'],
})
export class SetupCompleteForm implements OnInit, OnDestroy {
    @ViewChild('form', { static: true }) form: NgForm;
    @Input() formData: Company;
    @Output() valid: EventEmitter<any> = new EventEmitter<any>();

    ngOnInit(): void {
        this.form.statusChanges.subscribe(() => {
            this.valid.emit({ valid: this.form.valid, data: this.formData });
        });
    }

    ngOnDestroy(): void {
        this.valid.emit({ valid: this.form.valid, data: this.formData });
    }
}
