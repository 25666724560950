/**
 *
 * These error codes are originating from a Notion catalog
 * and more detailed information can be found here:
 * https://www.notion.so/humi/Error-Response-Catalog-5e04427732d44302b05927e38e7e97e8
 *
 */

export const PASSWORD_ERROR_CODES = ['HR-1001', 'HR-1007'];

export const ACCOUNT_STATUS_CODES = ['HR-1002', 'HR-1003', 'HR-1004', 'HR-1005', 'HR-1006', 'HR-1008'];

export const ACCOUNT_ERROR_CODES = ['HR-1012'];

export const EMAIL_ERROR_VALUES = {
    email: 'data.attributes.email',
};
