<div class="page-container centered-both-axes">
    <ui-card class="password-reset" [class.loading]="isLoading">
        <ui-flag-deprecated
            *ngIf="!authorized; else passwordStore"
            [header]="'storePassword.invalidLink' | translate"
            [icon]="'Exclamation'"
            [iconType]="'danger'"
        >
            <p class="flag-content">
                {{ 'storePassword.thisLinkHasExpiredOrIsInvalid' | translate }}
                <a routerLink="/login">{{ 'storePassword.clickHereToSignIn' | translate }}</a>
            </p>
        </ui-flag-deprecated>

        <ng-template #passwordStore>
            <ui-flag-deprecated
                *ngIf="passwordStored"
                [header]="'storePassword.passwordHasBeenStored' | translate"
                [icon]="'WhiteCheckMark'"
            >
                <p class="flag-content">
                    {{ 'storePassword.yourPasswordHasBeenStored' | translate }}
                    <span [innerHTML]="'storePassword.signInToAccessYourAccount' | translate"></span>
                </p>
            </ui-flag-deprecated>

            <form *ngIf="!passwordStored" #form="ngForm" (ngSubmit)="savePassword()">
                <h2 class="centered" level="1">
                    {{ (isOnboarding ? 'storePassword.createPassword' : 'storePassword.changePassword') | translate }}
                </h2>

                <div>
                    <mat-form-field class="w-100">
                        <mat-label>{{
                            (isOnboarding ? 'storePassword.password' : 'storePassword.newPassword') | translate
                        }}</mat-label>
                        <input
                            matInput
                            [type]="showPassword ? 'text' : 'password'"
                            name="password"
                            class="password"
                            required
                            [formControl]="password"
                            (ngModelChange)="validatePassword()"
                        />
                        <div class="tooltip">
                            <div class="item" [class.valid]="hasLowerCase">
                                <img class="icon" *ngIf="hasLowerCase" appIcon="WhiteCheckMark" />
                                <img class="icon" *ngIf="!hasLowerCase" appIcon="GreyCheckMark" />{{
                                    'storePassword.lowercaseCharacter' | translate
                                }}
                            </div>
                            <div class="item" [class.valid]="hasUpperCase">
                                <img class="icon" *ngIf="hasUpperCase" appIcon="WhiteCheckMark" />
                                <img class="icon" *ngIf="!hasUpperCase" appIcon="GreyCheckMark" />{{
                                    'storePassword.uppercaseCharacter' | translate
                                }}
                            </div>
                            <div class="item" [class.valid]="hasNumber">
                                <img class="icon" *ngIf="hasNumber" appIcon="WhiteCheckMark" />
                                <img class="icon" *ngIf="!hasNumber" appIcon="GreyCheckMark" />{{
                                    'storePassword.number' | translate
                                }}
                            </div>
                            <div class="item" [class.valid]="hasMinimumCharacters">
                                <img class="icon" *ngIf="hasMinimumCharacters" appIcon="WhiteCheckMark" />
                                <img class="icon" *ngIf="!hasMinimumCharacters" appIcon="GreyCheckMark" />
                                {{
                                    'storePassword.minimumCharacters'
                                        | translate: { numberOfCharacters: minimumCharacterCheck }
                                }}
                            </div>
                        </div>
                        <mat-icon
                            [svgIcon]="showPassword ? 'visibilityEnabled' : 'visiblityDisabled'"
                            (click)="toggleShowPassword()"
                            matSuffix
                        ></mat-icon>
                        <mat-error *ngIf="password.invalid">{{ password.errors | formErrors }}</mat-error>
                    </mat-form-field>

                    <mat-form-field class="w-100">
                        <mat-label>{{ 'storePassword.confirmPassword' | translate }}</mat-label>
                        <input
                            matInput
                            [type]="showPassword ? 'text' : 'password'"
                            name="password-confirm"
                            class="password"
                            required
                            [(ngModel)]="confirmPassword"
                            #confirmPasswordInput="ngModel"
                            (ngModelChange)="validatePassword()"
                        />
                        <div class="tooltip" style="top: -8px">
                            <div class="item" [class.valid]="passwordsMatch">
                                <img class="icon" *ngIf="passwordsMatch" appIcon="WhiteCheckMark" />
                                <img class="icon" *ngIf="!passwordsMatch" appIcon="GreyCheckMark" />{{
                                    'storePassword.passwordsMatch' | translate
                                }}
                            </div>
                        </div>
                    </mat-form-field>
                </div>

                <ui-button submit type="primary">{{ 'storePassword.setNewPassword' | translate }}</ui-button>

                <ui-button *ngIf="isOnboarding" type="link" (click)="goBack()">{{
                    'storePassword.cancel' | translate
                }}</ui-button>
                <ui-button *ngIf="!isOnboarding" type="link" url="/login">{{
                    'storePassword.backToSignIn' | translate
                }}</ui-button>
            </form>
        </ng-template>
    </ui-card>
</div>
