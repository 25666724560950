<div class="ui basic segment" [class.loading]="isLoading">
    <div class="flex justify-end">
        <ui-button class="context-menu" [matMenu]="contextMatMenu">
            <mat-icon svgIcon="meatballHorizontal"></mat-icon>
        </ui-button>
    </div>

    <h1 class="ui header">Debug</h1>

    <div class="ui hidden clear divider"></div>
    <div class="ui segment">
        <pre>{{ getEnv() | json }}</pre>
    </div>

    <div class="ui hidden clear divider"></div>

    <h2 class="ui header">Authentication Service</h2>
    <div class="ui segment">
        <pre>{{ getAuth() | json }}</pre>
    </div>

    <div class="ui hidden clear divider"></div>

    <h2 class="ui header">Tokens Service</h2>
    <div class="ui segment">
        <pre>{{ getToken() | json }}</pre>
    </div>

    <div class="ui hidden clear divider"></div>

    <h2 class="ui header">Abilities</h2>
    <div class="ui segment">
        <pre>{{ getAbilities() | json }}</pre>
    </div>

    <div class="ui hidden clear divider"></div>

    <h2 class="ui header">Account Model</h2>
    <div class="ui segment">
        <pre>{{ getAccount() | json }}</pre>
    </div>

    <div class="ui hidden clear divider"></div>

    <h2 class="ui header">Employee Model</h2>
    <div class="ui segment">
        <pre>{{ getEmployee() | json }}</pre>
    </div>

    <div class="ui hidden clear divider"></div>

    <h2 class="ui header">Company Model</h2>
    <div class="ui segment">
        <pre>{{ getCompany() | json }}</pre>
    </div>

    <div class="ui hidden clear divider"></div>

    <h2 class="ui header">Authentication Service - Role Model</h2>
    <div class="ui segment">
        <pre>{{ getRole() | json }}</pre>
    </div>

    <div class="ui hidden clear divider"></div>

    <h2 class="ui header">Authentication Service - Permission Models</h2>
    <div class="ui segment">
        <pre>{{ getPermissions() | json }}</pre>
    </div>

    <mat-menu #contextMatMenu>
        <!-- <div class="padding-x-2 margin-y-1">
            <a class="item" (click)="sendTestNotification()">
                <i class="fa fa-fw fa-share-square margin-right-1"></i> Test Notification</a
            >
        </div> -->
    </mat-menu>
</div>
