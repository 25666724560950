import { Component, computed, input, InputSignal, Signal } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { RouterLink } from '@angular/router';
import { KYC_KYB_URLS } from '@app/models/kyc-kyb/types';
import { PersonaScope } from '@app/modules/payroll/components/kyc-kyb/types';
import { TranslateModule } from '@ngx-translate/core';
import { BaseInformationBar } from '../base-information-type.component';

/**
 * Banner shown to users that must complete the Know-Your-Client or Know-Your-Business flow through Persona
 */
@Component({
    selector: 'special-info-kyc-kyb',
    standalone: true,
    imports: [TranslateModule, MatIconModule, RouterLink],
    templateUrl: './kyc-kyb-info-bar.template.html',
    styleUrls: ['./kyc-kyb-info-bar.style.scss'],
})
export class SpecialInfoKYCKYB extends BaseInformationBar {
    scope: InputSignal<PersonaScope> = input.required();

    shouldDisplay: Signal<boolean> = computed(() => {
        const { hasPayroll, isAdmin, KYCKYB, currentURL } = this.displayConditions() || {};

        if (!KYCKYB) {
            return false;
        }

        const { hasVerifiedPersonaIdentity, hasVerifiedPersonaBusiness } = KYCKYB;

        if (!hasPayroll) {
            return false;
        }

        // If we're already on the KYC or KYB page we don't need to direct to itself (KYC still links to KYB and vice-versa)
        if (KYC_KYB_URLS[this.scope()] === currentURL) {
            return false;
        }

        // KYC
        if (this.scope() === 'KYC') {
            return !hasVerifiedPersonaIdentity;
        }

        // KYB
        return !!isAdmin && !hasVerifiedPersonaBusiness;
    });

    get linkToPreRedirect(): string {
        return KYC_KYB_URLS[this.scope()];
    }
}
