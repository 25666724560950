import { Model } from '../core/base.model';
import { HireQuestion } from '@models/employee/hire-question.model';

export class HireAnswer extends Model {
    protected static _resource =
        'companyManagement/companies/:company/employees/:employee/hireQuestions/:hireQuestion/hireAnswer';

    protected static _version = 'v2';

    protected static _serializeAttributes = ['answer', 'question_id'];

    protected static _type = 'hireAnswers';

    get questionId(): string | null {
        return this._attributes['question_id'] || null;
    }

    set questionId(val: string) {
        this._attributes['question_id'] = val;
    }

    get answer(): string | null {
        return this._attributes['answer'] || null;
    }

    set answer(value: string) {
        this._attributes['answer'] = value;
    }

    question(): HireQuestion {
        return this.hasOne(HireQuestion, 'question');
    }
}
