import { Component } from '@angular/core';
import { Account } from '@app/models/account/account.model';
import { Company } from '@app/models/company/company.model';
import { Employee } from '@app/models/employee/employee.model';
import { AbilityService } from '@app/services';
import { AuthService } from '@app/services/auth.service';
import { TokenService } from '@app/services/token.service';
import { environment } from '@env/environment';

@Component({
    templateUrl: 'debug.view.html',
    styleUrls: ['debug.style.scss'],
})
export class DebugView {
    isLoading = false;

    constructor(
        private authService: AuthService,
        private token: TokenService,
        private abilities: AbilityService
    ) {}

    getEnv(): any {
        return environment;
    }

    getAbilities(): any {
        return this.abilities.all();
    }

    getAuth(): any {
        return {
            check: this.authService.isAuthenticated,
            user: this.authService.user.constructor.name,
            "can('administrate')": this.authService.isAdmin(),
        };
    }

    getToken(): any {
        return {
            token: this.token.token,
            role: this.token.roleName(),
            roleId: this.token.roleId(),
            accountId: this.token.accountId(),
            tokens: this.token.tokens(),
        };
    }

    getAccount(): Account {
        return this.authService.account;
    }

    getEmployee(): Employee | null {
        return this.authService.employee;
    }

    getCompany(): Company {
        return this.authService.company;
    }

    getRole(): any {
        return {
            name: this.authService.role.name,
            isImmutable: this.authService.role.isImmutable,
            description: this.authService.role.description,
        };
    }

    getPermissions(): any {
        return this.authService.role.permissions.map((permission) => permission.name);
    }
}
