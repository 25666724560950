<div class="ui basic login segment" [class.loading]="isLoading">
    <!-- Logout Menu -->
    <div class="menu">
        <ui-button [matMenu]="meatball" class="menu" size="no-padding">
            <mat-icon svgIcon="meatballHorizontal"></mat-icon>
        </ui-button>
        <mat-menu #meatball xPosition="before">
            <ng-container>
                <button (click)="logout($event)" mat-menu-item>{{ 'logout' | translate }}</button>
            </ng-container>
        </mat-menu>
    </div>

    <div class="container">
        <app-steps #stepHeader [steps]="formSteps()"></app-steps>

        <ng-container [ngSwitch]="step">
            <div *ngSwitchCase="Steps.Welcome" class="ui segment">
                <app-form-setup-welcome
                    (valid)="stepValidated($event.valid, $event.data, Steps.Welcome)"
                    [employee]="employee"
                    [formData]="stepData[Steps.Welcome]"
                ></app-form-setup-welcome>
                <div class="button-container">
                    <div class="ui buttons">
                        <ui-button (click)="onNextStep()" type="primary" [disabled]="!currentStepValid"
                            >{{ 'next' | appTranslate }}
                        </ui-button>
                    </div>
                </div>
            </div>

            <!-- Personal Step -->
            <div *ngSwitchCase="Steps.Personal">
                <app-form-setup-personal
                    #setupPersonalForm
                    (valid)="stepValidated($event.valid, $event.data, Steps.Personal)"
                    [formData]="stepData[Steps.Personal]"
                    [disableIdentityQuestions]="disableIdentityQuestions"
                ></app-form-setup-personal>
            </div>

            <div *ngSwitchCase="Steps.Documents" class="ui segment">
                <app-form-setup-documents
                    #setupDocumentsForm
                    (valid)="stepValidated($event.valid, $event.data, Steps.Documents)"
                    [formData]="stepData[Steps.Documents]"
                ></app-form-setup-documents>
            </div>

            <div *ngSwitchCase="Steps.Employment">
                <app-form-setup-employee
                    #setupEmployeeForm
                    (valid)="stepValidated($event.valid, $event.data, Steps.Employment)"
                    [bankingInformationOnboarding]="bankingInformationOnboarding"
                    [bankingInformationRequiredByDefault]="bankingInformationRequiredByDefault"
                    [isPayrollSyncEnabled]="isPayrollSyncEnabled"
                    [formData]="stepData[Steps.Employment]"
                ></app-form-setup-employee>
            </div>

            <div *ngSwitchCase="Steps.Survey" class="ui segment">
                <app-form-setup-survey
                    #setupSurveyForm
                    (valid)="stepValidated($event.valid, $event.data, Steps.Survey)"
                    [formData]="stepData[Steps.Survey]"
                ></app-form-setup-survey>
            </div>

            <div *ngSwitchCase="Steps.GettingStarted" class="ui segment">
                <h1 class="ui header centered">
                    {{ 'forms.setup-getStarted.almostReady' | translate }}
                    <div class="sub header">{{ 'forms.setup-getStarted.someThingsYouCanDo' | translate }}</div>
                </h1>

                <div class="bullet-points">
                    <div class="bullet">
                        <h3>&#8226; {{ 'forms.setup-getStarted.example1' | translate }}</h3>
                    </div>

                    <div class="bullet">
                        <h3>&#8226; {{ 'forms.setup-getStarted.example2' | translate }}</h3>
                    </div>

                    <div class="bullet">
                        <h3>&#8226; {{ 'forms.setup-getStarted.example3' | translate }}</h3>
                    </div>
                </div>

                <div class="button-container" style="margin-top: 2em">
                    <div class="ui buttons">
                        <ui-button (click)="done()" type="primary">{{
                            'forms.setup-getStarted.completeSetup' | translate
                        }}</ui-button>
                    </div>
                </div>
            </div>
        </ng-container>

        <div *ngIf="step > 0 && step < formSteps().length - 1" class="step-buttons">
            <button class="ui button" (click)="onBackStep()">&lt; {{ 'previous' | translate }}</button>
            <button class="ui primary button" (click)="onNextStep()">{{ 'next' | translate }} &gt;</button>
        </div>
    </div>
</div>
