import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { fetchFeatureFlagInterceptor, setupDataForFeatureFlagExtension } from '@app/functions/feature-flags.helpers';
import { inProduction } from '@app/functions';

import * as Sentry from '@sentry/angular';
import { Integrations } from '@sentry/tracing';
import { Dedupe as DedupeIntegration } from '@sentry/integrations';

if (environment.production) {
    window.console.log = function (_: unknown): void {
        // We don't want console logs in production
    };
    enableProdMode();
}

if (!inProduction()) {
    setupDataForFeatureFlagExtension();
    fetchFeatureFlagInterceptor();
}

if (environment.sentry) {
    Sentry.init({
        dsn: environment.sentry,
        environment: environment.name,
        release: environment.version + '.' + environment.build,
        integrations: [
            new Integrations.BrowserTracing({ tracingOrigins: [/.*api\.humi\.ca/] }),
            new DedupeIntegration(),
        ],
        tracesSampleRate: 0.2,
    });
}

platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .catch((err) => console.log(err));
