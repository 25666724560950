import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { Auth0Interceptor } from './interceptors/auth0-interceptor.class';
import { HumiTokenInterceptor } from './interceptors/humiToken-interceptor.class';

/**
 * Contains Angular's HttpClientModule as well as definitions for our HttpInterceptors which are hit on each request to the httpClient
 */
@NgModule({
    providers: [
        // Step 1: Add Auth0 token to request
        {
            provide: HTTP_INTERCEPTORS,
            useClass: Auth0Interceptor,
            multi: true,
        },
        // Step 2: Add Humi token to request
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HumiTokenInterceptor,
            multi: true,
        },
    ],
})
export class InterceptorModule {}
