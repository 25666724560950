<form #form="ngForm" autocomplete="off" [class.loading]="isLoading">
    <h1 class="ui header">{{ 'forms.setup-survey.header' | translate }}</h1>

    <ui-banner variant="info" class="margin-bottom-3">
        <p>
            <b>{{ 'forms.setup-survey.info1' | translate }}</b
            ><br />
            {{ 'forms.setup-survey.info2' | translate }}
        </p>
    </ui-banner>

    <div class="app-grid">
        <div *ngFor="let hireQuestion of hireQuestions">
            <mat-label>{{ hireQuestion.question }}</mat-label>
            <mat-form-field style="width: 100%">
                <textarea
                    matInput
                    required
                    [name]="'surveyAnswer' + hireQuestion.id"
                    rows="5"
                    [(ngModel)]="formData['surveyAnswer' + hireQuestion.id].answer"
                    #onboardingQuestion="ngModel"
                ></textarea>
                <mat-error *ngIf="onboardingQuestion.invalid">{{ onboardingQuestion.errors | formErrors }}</mat-error>
            </mat-form-field>
        </div>
    </div>
</form>
