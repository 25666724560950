import { Component, ElementRef } from '@angular/core';
import { SpecialInformationBarService } from '../../special-information-bar.service';
import { SpecialInformationBarTypes } from '../information-types';

/**
 * SpecialInformationBar is a bar which sometimes appears at the top of the screen
 * to alert the user of information which does not relate to a specific section of
 * the application.
 *
 * Don't put large UI in here because it takes up screen real estate.
 */
@Component({
    selector: 'app-special-information-bar',
    templateUrl: './special-information-bar.template.html',
    styleUrls: ['./special-information-bar.style.scss'],
    standalone: true,
    imports: [...SpecialInformationBarTypes],
    host: { role: 'alert' },
})
export class SpecialInformationBar {
    constructor(
        private elementRef: ElementRef<HTMLElement>,
        private specialInformationBarService: SpecialInformationBarService
    ) {
        this.specialInformationBarService.observeSpecialInformationBar(this.elementRef.nativeElement);
    }
}
