import { ErrorHandler, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@app/services';
import * as Sentry from '@sentry/browser';

import { HttpErrorResponse } from '@angular/common/http';
import { FeatureFlag } from '@app/enums';
import { ForbiddenError } from '@app/errors/http/400/forbidden.error';
import { UnauthorizedError } from '@app/errors/http/400/unauthorized.error';
import { HttpError } from '@app/errors/http/http.error';
import { FeatureService } from '@app/services/feature.service';
import { environment } from '@env/environment';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
    constructor(
        private router: Router,
        private featureService: FeatureService,
        private authService: AuthService
    ) {}

    async handleError(error: any): Promise<void> {
        // Check if its a generic angular error, convert to our error class
        if (error instanceof HttpErrorResponse || error.rejection instanceof HttpErrorResponse) {
            error = new HttpError(error.rejection || error);
            return;
        }

        if (error instanceof UnauthorizedError || error.rejection instanceof UnauthorizedError) {
            this.reauthenticate();
            return;
        }

        if (error instanceof ForbiddenError || error.rejection instanceof ForbiddenError) {
            this.reauthenticate();
            return;
        }

        if (error.toString().includes('ExpressionChangedAfterItHasBeenCheckedError')) {
            console.error(error);
            return;
        }

        if (error?.status === 401) {
            this.reauthenticate();
            return;
        }

        // Check if this is a cache issue were the server has updated the chunks but the local cache
        // still points at old files
        if (error?.message && error.message.indexOf('Loading chunk') > -1 && error.message.indexOf('failed') > -1) {
            window.location.reload();
            return;
        }

        this.sendToConsole(error);
        this.sendToSentry(error);

        if (await this.featureService.has(FeatureFlag.errorPage)) {
            this.router.navigateByUrl('/client-error');

            /*
            Above we navigate to an error page, we could alternatively rethrow the error here
            and prevent angular from moving forward (avoiding looping of template errors)
            but this would result in the client just getting stuck

            if (error instanceof Error) {
                throw error;
            }

            throw new Error(error);
            */
        }
    }

    private reauthenticate(): void {
        this.authService.reauthenticate();
    }

    private sendToConsole(error: any): void {
        if (console?.group && console?.error) {
            console.group('Humi Error Log Service');
            console.log(error);
            console.groupEnd();
        }
    }

    private sendToSentry(error: any): void {
        if (['demo', 'production', 'qa', 'e2e'].includes(environment.name)) {
            Sentry.captureException(error.originalError || error);
        }
    }
}
