import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ImageAcknowledgerComponent, PdfViewerDialogComponent } from '@app/components';
import { FeatureFlag } from '@app/enums';
import { DocumentAcknowledgement } from '@app/models/documents/document-acknowledgement.model';
import { EmployeeDocument } from '@app/models/documents/employee-document.model';
import { SignatureRequest } from '@app/models/documents/signature-request.model';
import { FileHelperService, NotifyService } from '@app/services';
import { AuthService } from '@app/services/auth.service';
import { FeatureService } from '@app/services/feature.service';
import { Employee } from '@employees/../../models/employee/employee.model';
import { HelloSignEmbedService } from '@app/services/hello-sign-embed.service';

@Component({
    selector: 'app-form-setup-documents',
    templateUrl: './setup-documents.form.html',
    styleUrls: ['./setup-documents.style.scss', './setup-documents.responsive.scss'],
    providers: [HelloSignEmbedService],
})
export class SetupDocumentsForm implements OnInit, OnDestroy {
    @ViewChild('form', { static: true }) form: NgForm;
    @ViewChild('pdfViewer', { static: true }) pdfViewer: PdfViewerDialogComponent;
    @ViewChild('imageViewer') imageViewer: ImageAcknowledgerComponent;
    @Input() formData: any = {};
    @Output() valid: EventEmitter<any> = new EventEmitter<any>();

    showAcknowledgementButton = false;
    isLoading = true;
    documents: boolean[] = [];
    employee: Employee;
    employeeDocuments: EmployeeDocument[] = [];
    activeAssignment: DocumentAcknowledgement | SignatureRequest | null = null;
    activeDocument: EmployeeDocument | null = null;
    allowMultiSignDocuments = false;
    pdfViewerOpen = false;
    isValid = false;

    constructor(
        private auth: AuthService,
        private fileHelper: FileHelperService,
        private notify: NotifyService,
        private helloSign: HelloSignEmbedService,
        private featureService: FeatureService
    ) {}

    onSign(document: EmployeeDocument): void {
        if (document.isComplete()) {
            return;
        }

        if (!document.isSignatureRequest()) {
            this.openAcknowledgementOrPreview(document);
            return;
        }

        this.openSigning(document);
    }

    areDocumentsComplete(): boolean {
        return this.employeeDocuments
            .filter((doc: EmployeeDocument) => doc.isActionable())
            .every((employeeDocument: EmployeeDocument) => employeeDocument.isComplete());
    }

    async ngOnInit(): Promise<void> {
        await this.setFeatureFlag();
        this.getEmployeeDocuments();
    }

    ngOnDestroy(): void {
        this.valid.emit({
            valid: this.areDocumentsComplete(),
            data: this.formData,
        });
    }

    submit(): void {
        this.isValid = this.areDocumentsComplete();
        if (!this.isValid) {
            this.notify.error('forms.setup-documents.reviewBeforeProceedingError');
        }
    }

    onAcknowledge(): void {
        const acknowledgement = this.activeAssignment as DocumentAcknowledgement;

        acknowledgement.acknowledgedAt = new Date();
        this.isLoading = true;
        acknowledgement
            .param('companyDocument', acknowledgement.companyDocumentId)
            .save()
            .then(() => {
                this.isLoading = false;
                this.notify.success('forms.setup-documents.documentAcknowledgedMessage');
                if (this.activeDocument?.file?.isSupportedImageType) {
                    this.imageViewer.close();
                } else {
                    this.pdfViewer.onClose();
                }
                this.valid.emit({
                    valid: this.areDocumentsComplete(),
                    data: this.formData,
                });
            })
            .catch((err) => {
                this.isLoading = false;
                this.notify.error(err);
                if (this.activeDocument?.file?.isSupportedImageType) {
                    this.imageViewer.close();
                } else {
                    this.pdfViewer.onClose();
                }
            });
    }

    private async openSigning(employeeDocument: EmployeeDocument): Promise<void> {
        // remove any previous callbacks in case the user has opened
        // a document and then closed it.
        this.helloSign.removeSignCallbacks();

        let signatureRequest: SignatureRequest;
        try {
            signatureRequest = await SignatureRequest.param(
                'signingTemplate',
                (employeeDocument.source as SignatureRequest).signingTemplateId
            ).find(employeeDocument.source.id);
        } catch (e) {
            this.notify.error(e);
            return;
        }

        employeeDocument.source = signatureRequest;

        if (signatureRequest && signatureRequest.isComplete()) {
            this.notify.success('forms.setup-documents.documentAlreadySignedMessage');
            this.valid.emit({
                valid: this.areDocumentsComplete(),
                data: this.formData,
            });
            return;
        }

        this.helloSign.onSignEventOnce(async () => {
            try {
                await this.auth.checkThenReauth();

                this.isLoading = true;
                signatureRequest.completedAt = new Date();
                await signatureRequest.save();
                this.notify.success('forms.setup-documents.documentSignedMessage');
            } catch (e) {
                this.notify.error(e);
            }
            this.valid.emit({
                valid: this.areDocumentsComplete(),
                data: this.formData,
            });
            this.isLoading = false;
        });

        await this.helloSign.open(signatureRequest.signingUrl, null, true);
    }

    private async openAcknowledgementOrPreview(employeeDocument: EmployeeDocument): Promise<void> {
        const employeeDocumentWithFile: EmployeeDocument = await EmployeeDocument.param(
            'employee',
            employeeDocument.employeeId
        )
            .with('file')
            .find(employeeDocument.id);
        this.activeAssignment = employeeDocument.source as DocumentAcknowledgement;
        this.showAcknowledgementButton = employeeDocument.isAcknowledgement();
        this.activeDocument = employeeDocumentWithFile;

        if (employeeDocumentWithFile.file.isSupportedImageType) {
            this.imageViewer.open(employeeDocumentWithFile.file);
            return;
        }

        this.pdfViewer.setSource(this.fileHelper.path(employeeDocumentWithFile.file.id));
        this.pdfViewerOpen = true;
    }

    private async getEmployeeDocuments(): Promise<void> {
        this.isLoading = true;
        const [employeeDocs] = await EmployeeDocument.param('employee', this.auth.employee.id)
            // TODO: remove 'when' clause when Multi Sign Onboarding Doc feature is released
            .when(this.allowMultiSignDocuments, (query) => query.where('forOnboarding', true))
            .all();
        this.employeeDocuments = employeeDocs;
        this.valid.emit({
            valid: this.areDocumentsComplete(),
            data: this.formData,
        });
        this.isLoading = false;
    }

    private async setFeatureFlag(): Promise<void> {
        this.allowMultiSignDocuments = await this.featureService.has(FeatureFlag.onboardingMultiSignDocuments);
    }
}
