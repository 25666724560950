<form #form="ngForm" autocomplete="off">
    <h1 class="ui header">
        Welcome to Humi,
        <span class="capitalize">{{ auth?.user?.account?.fullName }}</span>
        <div class="sub header">
            We're so excited you've chosen Humi to manage your HR! You're only a few simple steps away from getting
            started.
        </div>
    </h1>

    <div class="app-grid">
        <mat-form-field class="columns-6 small-columns-12">
            <mat-label>Company Name</mat-label>
            <input
                matInput
                type="text"
                placeholder="ABC Inc"
                required
                #name="ngModel"
                name="name"
                [(ngModel)]="formData.name"
            />
            <mat-error *ngIf="name.invalid">{{ name.errors | formErrors }}</mat-error>
        </mat-form-field>

        <mat-form-field class="columns-6 small-columns-12">
            <mat-label>Date Founded</mat-label>
            <input
                matInput
                placeholder="Select a date..."
                required
                #incorporationDate="ngModel"
                name="incorporationDate"
                [(ngModel)]="formData.incorporationDate"
                [matDatepicker]="picker"
            />
            <mat-datepicker-toggle matSuffix [for]="picker">
                <mat-icon matDatepickerToggleIcon svgIcon="calendar"></mat-icon>
            </mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>

            <mat-error *ngIf="incorporationDate.invalid">{{ incorporationDate.errors | formErrors }}</mat-error>
        </mat-form-field>

        <mat-form-field class="columns-6 small-columns-12">
            <mat-label>Company Phone</mat-label>
            <input
                matInput
                type="text"
                placeholder="123-123-1234"
                required
                #phonePrimary="ngModel"
                name="phonePrimary"
                [(ngModel)]="formData.phonePrimary"
            />
            <mat-error *ngIf="phonePrimary.invalid">{{ phonePrimary.errors | formErrors }}</mat-error>
        </mat-form-field>

        <mat-form-field class="columns-6 small-columns-12">
            <mat-label>Street Address</mat-label>
            <input
                matInput
                type="text"
                placeholder="Search for an address..."
                required
                #address="ngModel"
                name="address"
                [(ngModel)]="formData.address"
            />
            <mat-error *ngIf="address.invalid">{{ address.errors | formErrors }}</mat-error>
        </mat-form-field>

        <mat-form-field class="columns-6 small-columns-12">
            <mat-label>Country</mat-label>

            <ui-country-autocomplete
                placeholder="Please pick"
                name="country"
                #country="ngModel"
                required
                [(ngModel)]="formData.country"
            ></ui-country-autocomplete>

            <mat-error *ngIf="country.invalid">{{ country.errors | formErrors }}</mat-error>
        </mat-form-field>

        <mat-form-field class="columns-6 small-columns-12">
            <mat-label>City</mat-label>
            <input matInput type="text" required #city="ngModel" name="city" [(ngModel)]="formData.city" />
            <mat-error *ngIf="city.invalid">{{ city.errors | formErrors }}</mat-error>
        </mat-form-field>

        <mat-form-field class="columns-6 small-columns-12">
            <mat-label>State/Province</mat-label>

            <ui-state-autocomplete
                required
                placeholder="Select state..."
                name="province"
                [country]="formData.country"
                [(ngModel)]="formData.province"
                #state="ngModel"
            ></ui-state-autocomplete>
            <mat-error *ngIf="state.invalid">{{ state.errors | formErrors }}</mat-error>
        </mat-form-field>

        <mat-form-field class="columns-6 small-columns-12">
            <mat-label>Postal Code</mat-label>
            <input
                matInput
                type="text"
                required
                #postalCode="ngModel"
                name="postal_code"
                [(ngModel)]="formData.postalCode"
            />
            <mat-error *ngIf="postalCode.invalid">{{ postalCode.errors | formErrors }}</mat-error>
        </mat-form-field>

        <mat-form-field class="columns-6 small-columns-12">
            <mat-label>Company Logo</mat-label>
            <ui-mat-file-upload
                #logoInput="ngModel"
                name="logoId"
                [ngModel]="logo"
                (fileSelected)="saveLogo($event)"
                placeholder="Select a logo"
                [allowedTypes]="['.png', '.gif', '.jpg', '.jpeg', '.svg']"
            ></ui-mat-file-upload>
            <ui-button type="outline">Browse</ui-button>
            <mat-error *ngIf="logoInput.invalid">{{ logoInput.errors | formErrors }}</mat-error>
        </mat-form-field>
    </div>
</form>
