import {
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    QueryList,
    ViewChild,
    ViewChildren,
} from '@angular/core';
import { NgForm } from '@angular/forms';
import { bankAccountMask, bankInstitutionMask, bankTransitMask, sinNumberMask } from '@app/functions';
import { Employee } from '@app/models/employee/employee.model';
import { DataFieldGroup } from '@app/models/settings/data-field-group.model';
import { DataFieldsService } from '@app/modules/employees/services';
import { AuthService } from '@app/services';
import { DataValue } from '@models/employee/data-value.model';
import { DataField } from '@models/settings/data-field.model';
import { DefaultGroupFieldNamesEnum } from '@settings/enums/default-group-field-names.enum';
import { DataFieldsForm } from '@forms/employees/components';
import { mergeDataFieldsById } from '@employees/functions/merge-data-fields-by-id';
import { mergeDataValuesById } from '@employees/functions/merge-data-values-by-id';

@Component({
    selector: 'app-form-setup-employee',
    templateUrl: './setup-employee.form.html',
    providers: [DataFieldsService],
    styleUrls: ['./setup-employee.responsive.scss'],
})
export class SetupEmployeeForm implements OnInit {
    @ViewChild('form', { static: true }) form: NgForm;
    @ViewChild('employmentInformationDataFields') employmentInformationDataFields: DataFieldsForm;
    @ViewChild('bankAccountDataFields') bankAccountDataFields: DataFieldsForm;
    @ViewChild('emergencyContactsDataFields') emergencyContactsDataFields: DataFieldsForm;
    @ViewChild('editDataFieldsForm') editDataFieldsForm: DataFieldsForm | undefined;
    @ViewChildren('editDataFieldsForm') editDataFieldsForms: QueryList<DataFieldsForm>;
    @Input() formData: any = {};
    @Input() bankingInformationOnboarding = false;
    @Input() bankingInformationRequiredByDefault = false;
    @Input() bankingInformationRequiredByState = false;
    @Input() isPayrollSyncEnabled = false;
    @Output() valid: EventEmitter<any> = new EventEmitter<any>();
    sinTextMask = sinNumberMask;
    bankInstitutionMask = bankInstitutionMask;
    bankTransitMask = bankTransitMask;
    bankAccountMask = bankAccountMask;
    defaultGroupCustomDataValues: DataValue[] = [];
    defaultGroupCustomDataFields: DataField[] = [];
    customDataFields: DataField[] = [];
    customDataFieldGroups: DataFieldGroup[] = [];
    employee: Employee;
    defaultGroupFieldNames = DefaultGroupFieldNamesEnum;
    isValid = false;
    isSinRequired = false;

    constructor(
        private changeDetector: ChangeDetectorRef,
        private auth: AuthService,
        public dataFieldsService: DataFieldsService
    ) {
        this.employee = this.auth.employee;
    }

    ngOnInit(): void {
        this.setCustomFieldsImprovements();

        if (this.bankingInformationOnboarding) {
            this.onBankingInformationChange();
        }

        /* If users have preimported SIN number with space, it will be considered invalid when the page first loaded. */
        if (this.formData.sin) {
            this.formData.sin = this.formData.sin.replace(/\s/g, '');
        }

        this.isSinRequired = this.employee.isSinRequired;
    }

    onBankingInformationChange(): void {
        if (this.bankingInformationRequiredByDefault) {
            this.bankingInformationRequiredByState = true;
            this.changeDetector.detectChanges();
            return;
        }

        this.bankingInformationRequiredByState = Boolean(
            this.formData.bankAccount || this.formData.bankBranch || this.formData.bankInstitution
        );
        this.changeDetector.detectChanges();
    }

    emitFormDataAndFormValidation(): void {
        this.formData.customDataFieldValues = this.defaultGroupCustomDataValues;
        this.formData.customDataFields = this.defaultGroupCustomDataFields;
        this.valid.emit({ valid: this.isValid, data: this.formData });
    }

    submit(): void {
        this.form.onSubmit(new Event('submit'));
        this.employmentInformationDataFields.form.onSubmit(new Event('submit'));
        this.bankAccountDataFields.form.onSubmit(new Event('submit'));
        this.emergencyContactsDataFields.form.onSubmit(new Event('submit'));
        this.editDataFieldsForms.forEach((dataFieldsForm) => dataFieldsForm.form.onSubmit(new Event('submit')));
        this.setIsValid();
        this.emitFormDataAndFormValidation();
    }

    setIsValid(): void {
        this.isValid =
            this.form.valid &&
            this.employmentInformationDataFields.form.valid &&
            this.bankAccountDataFields.form.valid &&
            this.emergencyContactsDataFields.form.valid &&
            this.editDataFieldsForms.toArray().every((dataFieldsForm) => dataFieldsForm.form.valid);
    }

    private async setCustomFieldsImprovements(): Promise<void> {
        this.defaultGroupCustomDataFields = (await this.dataFieldsService.getEmployeeDataFields())
            .filter((dataField) => dataField.editable)
            .filter((dataField) => {
                if (dataField.dataFieldGroup.component === 'job_and_pay') {
                    return true;
                }
                return dataField.dataFieldGroup.name === DefaultGroupFieldNamesEnum.emergency_contacts;
            });

        this.defaultGroupCustomDataFields = mergeDataFieldsById(
            this.defaultGroupCustomDataFields,
            this.formData.customDataFields ?? []
        );

        this.customDataFields = this.defaultGroupCustomDataFields.filter(
            (dataField) => dataField.dataFieldGroup.isCustom
        );

        if (this.auth.can(DataValue.permission.view)) {
            const dataFieldIds = this.defaultGroupCustomDataFields.map((dataField) => dataField.id);

            this.defaultGroupCustomDataValues = (await this.dataFieldsService.getEmployeeDataValues())
                .filter((dataValue) => dataValue.canEdit)
                .filter((dataValue) => dataFieldIds.includes(dataValue.dataFieldId));

            this.defaultGroupCustomDataValues = mergeDataValuesById(
                this.defaultGroupCustomDataValues,
                this.formData.customDataFieldValues ?? []
            );
        }
        this.setCustomDataFieldGroups();
    }

    private setCustomDataFieldGroups(): void {
        this.customDataFieldGroups = this.dataFieldsService
            .getUniqueGroupsFromDataFields(this.defaultGroupCustomDataFields)
            .filter(
                (dataFieldGroup) =>
                    this.dataFieldsService.getDataFieldsByGroup(dataFieldGroup.id, this.customDataFields).length > 0
            )
            .sort((dataFieldGroup1, dataFieldGroup2) => dataFieldGroup1.orderBy - dataFieldGroup2.orderBy);
    }
}
