<form #form="ngForm" autocomplete="off">
    <!-- Personal Basic Information -->
    <ui-card>
        <h2 class="ui header">{{ 'forms.setup-personal.basicInfoHeader' | translate }}</h2>
        <p>{{ 'forms.setup-personal.basicInfoSubheader' | translate }}</p>

        <div class="app-grid">
            <mat-form-field class="columns-4 small-columns-12">
                <mat-label>{{ 'forms.setup-personal.firstNameLabel' | translate }}</mat-label>
                <input
                    matInput
                    type="text"
                    required
                    #firstName="ngModel"
                    name="firstName"
                    [(ngModel)]="formData.firstName"
                />
                <mat-error *ngIf="firstName.invalid">{{ firstName.errors | formErrors }}</mat-error>
            </mat-form-field>
            <mat-form-field class="columns-4 small-columns-12">
                <mat-label>{{ 'forms.setup-personal.lastNameLabel' | translate }}</mat-label>
                <input
                    matInput
                    type="text"
                    required
                    #lastName="ngModel"
                    name="lastName"
                    [(ngModel)]="formData.lastName"
                />
                <mat-error *ngIf="lastName.invalid">{{ lastName.errors | formErrors }}</mat-error>
            </mat-form-field>

            <!---Audio Recording-->
            <div *ngIf="shouldShowEmployeeNamePronunciation" class="audioRecorder">
                <mat-label>{{ 'forms.setup-personal.namePronunciationLabel' | translate }}</mat-label>
                <app-audio-recorder
                    #audioRecorder
                    [(audioBlob)]="audioBlob"
                    [(supportedFormat)]="supportedAudioFormat"
                ></app-audio-recorder>
            </div>

            <!-- Genders -->
            <ng-container *ngIf="!disableIdentityQuestions">
                <div class="app-grid">
                    <add-genders
                        class="columns-4"
                        [initialGenders]="formData.genders"
                        (emitAllGenders)="setGenders($event)"
                    ></add-genders>
                </div>

                <!-- Pronouns -->
                <div class="app-grid">
                    <add-pronouns
                        class="columns-4"
                        [initialPronouns]="formData.pronouns"
                        (emitAllPronouns)="setPronouns($event)"
                    ></add-pronouns>
                </div>
            </ng-container>
        </div>

        <app-form-data-fields
            #basicInformationDataFields
            columnClass="columns-6"
            [employee]="employee"
            [dataFieldGroup]="defaultGroupFieldNames.basic_information"
            [dataFields]="defaultGroupCustomDataFields"
            [(dataValues)]="defaultGroupCustomDataValues"
            [emitFormValidation]="true"
            [isDefaultGroup]="true"
        ></app-form-data-fields>
    </ui-card>

    <!-- Personal Legal Information -->
    <ui-card>
        <h2 class="ui header">{{ 'forms.setup-personal.legalInfoHeader' | translate }}</h2>
        <p>{{ 'forms.setup-personal.legalInfoSubheader' | translate }}</p>

        <div class="app-grid">
            <mat-form-field class="columns-4 small-columns-12">
                <mat-label>{{ 'forms.setup-personal.legalFirstNameLabel' | translate }}</mat-label>
                <input
                    matInput
                    type="text"
                    required
                    #legalFirstName="ngModel"
                    name="legalFirstName"
                    [(ngModel)]="formData.legalFirstName"
                />
                <mat-error *ngIf="legalFirstName.invalid">{{ legalFirstName.errors | formErrors }}</mat-error>
            </mat-form-field>
            <mat-form-field class="columns-2 small-columns-12">
                <mat-label>{{ 'forms.setup-personal.legalMiddleNameLabel' | translate }}</mat-label>
                <input
                    matInput
                    type="text"
                    #legalMiddleName="ngModel"
                    name="legalMiddleName"
                    [(ngModel)]="formData.legalMiddleName"
                />
                <mat-error *ngIf="legalLastName.invalid">{{ legalMiddleName.errors | formErrors }}</mat-error>
            </mat-form-field>
            <mat-form-field class="columns-4 small-columns-12">
                <mat-label>{{ 'forms.setup-personal.legalLastNameLabel' | translate }}</mat-label>
                <input
                    matInput
                    type="text"
                    required
                    #legalLastName="ngModel"
                    name="legalLastName"
                    [(ngModel)]="formData.legalLastName"
                />
                <mat-error *ngIf="legalLastName.invalid">{{ legalLastName.errors | formErrors }}</mat-error>
            </mat-form-field>
        </div>

        <div class="app-grid">
            <mat-form-field class="columns-5 small-columns-12">
                <mat-label>{{ 'forms.setup-personal.dateOfBirthLabel' | translate }}</mat-label>
                <input
                    matInput
                    type="text"
                    placeholder="{{ 'forms.setup-personal.dateOfBirthPlaceholder' | translate }}"
                    required
                    dateOfBirthValidator
                    #bornOn="ngModel"
                    name="bornOn"
                    [(ngModel)]="formData.bornOn"
                    [matDatepicker]="picker"
                    [min]="minBornOnDate"
                    [max]="maxBornOnDate"
                />

                <mat-datepicker-toggle matSuffix [for]="picker">
                    <mat-icon matDatepickerToggleIcon svgIcon="calendar"></mat-icon>
                </mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>

                <mat-error *ngIf="bornOn.invalid">{{ bornOn.errors | formErrors }}</mat-error>
            </mat-form-field>

            <div class="app-grid" class="columns-5 small-columns-12">
                <div class="columns-4 notranslate">
                    <!-- Legal Sex -->
                    <div class="app-grid">
                        <mat-form-field class="columns-10">
                            <mat-label appRemoveAriaOwns>{{
                                'forms.setup-personal.legalSexLabel' | translate
                            }}</mat-label>
                            <mat-select
                                type="text"
                                placeholder="{{ 'forms.setup-personal.legalSexPlaceholder' | translate }}"
                                required
                                #legalSex="ngModel"
                                name="legalSex"
                                [(ngModel)]="formData.legalSex"
                            >
                                <mat-option value="F">
                                    {{ 'forms.setup-personal.femaleLabel' | translate }}
                                </mat-option>
                                <mat-option value="M"> {{ 'forms.setup-personal.maleLabel' | translate }} </mat-option>
                                <mat-option value="X">
                                    {{ 'forms.setup-personal.otherLegalSexLabel' | translate }}
                                </mat-option>
                                <mat-option value="P">
                                    {{ 'forms.setup-personal.preferNotToSayLabel' | translate }}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="legalSex.invalid">{{ legalSex.errors | formErrors }}</mat-error>
                        </mat-form-field>

                        <!-- Legal sex tooltip  -->
                        <ui-info
                            class="columns-2 padding-left-2 legal-sex-tooltip-mobile"
                            text="{{ 'forms.setup-personal.legalSexTooltip' | translate }}"
                            matTooltipClass="allow-multi-line-tooltip"
                        ></ui-info>
                    </div>
                </div>
            </div>
        </div>

        <!---Birthday Visibility-->
        <div *ngIf="shouldShowBirthdayVisibility">
            <mat-radio-group
                name="birthdayPrivacy"
                #birthdayPrivacy="ngModel"
                required
                [(ngModel)]="formData.isBirthdayPrivate"
            >
                <mat-label>{{ 'forms.setup-personal.birthdayPrivacyLabel' | translate }}</mat-label>
                <div class="birthday-privacy">
                    <img src="/assets/img/misc/birthday-visibility.png" />
                    <mat-radio-button name="Public" [value]="false">{{
                        'forms.setup-personal.birthdayPublicOption' | translate
                    }}</mat-radio-button>
                    <mat-radio-button name="Private" [value]="true">{{
                        'forms.setup-personal.birthdayPrivateOption' | translate
                    }}</mat-radio-button>
                    <mat-error *ngIf="birthdayPrivacy.invalid">{{ birthdayPrivacy.errors | formErrors }}</mat-error>
                </div>
            </mat-radio-group>
        </div>

        <app-form-data-fields
            #personalInformationDataFields
            columnClass="columns-6"
            [employee]="employee"
            [dataFieldGroup]="defaultGroupFieldNames.personal_information"
            [dataFields]="defaultGroupCustomDataFields"
            [(dataValues)]="defaultGroupCustomDataValues"
            [emitFormValidation]="true"
            [isDefaultGroup]="true"
        ></app-form-data-fields>

        <div class="app-grid">
            <mat-form-field class="columns-4 small-columns-12">
                <mat-label>{{ 'forms.setup-personal.countryLabel' | translate }}</mat-label>

                <ui-country-autocomplete
                    name="country"
                    #country="ngModel"
                    required
                    [(ngModel)]="formData.country"
                ></ui-country-autocomplete>

                <mat-error *ngIf="country.invalid">{{ country.errors | formErrors }}</mat-error>
            </mat-form-field>
            <mat-form-field class="columns-4 small-columns-12">
                <mat-label>{{ 'forms.setup-personal.provinceStateLabel' | translate }}</mat-label>
                <ui-state-autocomplete
                    placeholder="{{ 'forms.setup-personal.provinceStatePlaceholder' | translate }}"
                    name="province"
                    #province="ngModel"
                    required
                    [country]="formData.country"
                    [(ngModel)]="formData.province"
                    (ngModelChange)="onProvinceChange()"
                ></ui-state-autocomplete>
                <mat-error *ngIf="province.invalid">{{ province.errors | formErrors }}</mat-error>
            </mat-form-field>
        </div>

        <div class="app-grid">
            <mat-form-field class="columns-4 small-columns-12">
                <mat-label>{{ 'forms.setup-personal.cityLabel' | translate }}</mat-label>
                <input matInput type="text" required #city="ngModel" name="city" [(ngModel)]="formData.city" />
                <mat-error *ngIf="city.invalid">{{ city.errors | formErrors }}</mat-error>
            </mat-form-field>

            <ng-container *ngIf="formData.country === 'Canada'">
                <mat-form-field class="columns-4 small-columns-12">
                    <mat-label>{{ 'forms.setup-personal.postalCodeLabel' | translate }}</mat-label>
                    <input
                        matInput
                        type="text"
                        required
                        canadianPostalCodeValidator
                        #canadianPostalCode="ngModel"
                        name="canadianPostalCode"
                        [textMask]="postalCodeMask"
                        [(ngModel)]="formData.postalCode"
                    />
                    <mat-error *ngIf="canadianPostalCode.invalid">{{
                        canadianPostalCode.errors | formErrors
                    }}</mat-error>
                </mat-form-field>
            </ng-container>

            <ng-container *ngIf="formData.country !== 'Canada'">
                <mat-form-field class="columns-4 small-columns-12">
                    <mat-label>{{ 'forms.setup-personal.zipPostalCodeLabel' | translate }}</mat-label>
                    <input
                        matInput
                        type="text"
                        required
                        usaZipCodeValidator
                        [country]="formData.country"
                        #postalCode="ngModel"
                        name="postalCode"
                        [(ngModel)]="formData.postalCode"
                    />
                    <mat-error *ngIf="postalCode.invalid">{{ postalCode.errors | formErrors }}</mat-error>
                </mat-form-field>
            </ng-container>
        </div>

        <div class="app-grid">
            <mat-form-field class="columns-4 small-columns-12">
                <mat-label>{{ 'forms.setup-personal.addressLabel' | translate }}</mat-label>
                <input
                    matInput
                    type="text"
                    required
                    #addressLine1="ngModel"
                    name="addressLine1"
                    [(ngModel)]="formData.addressLine1"
                />
                <mat-error *ngIf="addressLine1.invalid">{{ addressLine1.errors | formErrors }}</mat-error>
            </mat-form-field>
        </div>

        <div class="app-grid">
            <mat-form-field class="columns-4 small-columns-12">
                <mat-label>{{ 'forms.setup-personal.phoneLabel' | translate }}</mat-label>
                <input
                    matInput
                    type="text"
                    required
                    #phoneMobile="ngModel"
                    name="phoneMobile"
                    [(ngModel)]="formData.phoneMobile"
                />
                <mat-error *ngIf="phoneMobile.invalid">{{ phoneMobile.errors | formErrors }}</mat-error>
            </mat-form-field>
        </div>

        <app-form-data-fields
            #addressDataFields
            columnClass="columns-6"
            [employee]="employee"
            [dataFieldGroup]="defaultGroupFieldNames.address"
            [dataFields]="defaultGroupCustomDataFields"
            [(dataValues)]="defaultGroupCustomDataValues"
            [emitFormValidation]="true"
            [isDefaultGroup]="true"
        ></app-form-data-fields>
    </ui-card>

    <!-- Custom Fields -->
    <ng-container *ngIf="customDataFieldGroups.length > 0">
        <ui-card *ngFor="let group of customDataFieldGroups">
            <app-segment-header [title]="group.name"></app-segment-header>
            <app-form-data-fields
                #editDataFieldsForm
                [employee]="employee"
                [dataFieldGroup]="group.name"
                [dataFields]="dataFieldsService.getDataFieldsByGroup(group.id, customDataFields)"
                [(dataValues)]="defaultGroupCustomDataValues"
                [columnClass]="'columns-4'"
                [emitFormValidation]="true"
            ></app-form-data-fields>
        </ui-card>
    </ng-container>
</form>
