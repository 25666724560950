import { Component, OnInit } from '@angular/core';
import * as Sentry from '@sentry/browser';
import { TokenService } from '@app/services/token.service';
import { AUTH_ERROR } from '@app/components/auth0/auth0.view';

@Component({
    templateUrl: 'client-error.view.html',
    styleUrls: ['../not-found/not-found.style.scss'],
})
export class ClientErrorView implements OnInit {
    isErrors = false;
    errorMessage = '';

    constructor(private tokenService: TokenService) {}

    async ngOnInit(): Promise<void> {
        const errors = localStorage.getItem(AUTH_ERROR);
        if (errors && !this.tokenService.auth0Token) {
            localStorage.removeItem(AUTH_ERROR);
            this.isErrors = true;
            Sentry.captureException(errors);
            this.errorMessage = errors;
            return;
        }
    }
}
