@if (shouldDisplay()) {
    <div class="webinar-information">
        <div class="start-column"></div>
        <div class="content">
            <div>
                <span class="margin-right-1">🖥️</span>{{ 'self-serve.quickstart.joinOurWeeklyWebinars' | translate }}
            </div>
            <ui-button [externalLink]="true" url="https://www.humi.ca/implementation-webinars" type="primary">{{
                'self-serve.register' | translate
            }}</ui-button>
            <app-trial-days-remaining [trialExpiresAt]="trialExpiresAt" />
        </div>
        <div class="end-column">
            <ui-button type="icon" (click)="hide()" [aria-label]="'self-serve.quickstart.dismissQuickstart' | translate"
                ><mat-icon svgIcon="close"></mat-icon
            ></ui-button>
        </div>
    </div>
}
