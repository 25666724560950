<form class="ui form" #form="ngForm" autocomplete="off">
    <h2 class="ui header centered">
        <ng-container *ngIf="company">
            {{ 'forms.setup-welcome.header' | translate: { company_name: company?.name } }}
        </ng-container>
        <div class="sub header">{{ 'forms.setup-welcome.subheader' | translate }}</div>
    </h2>

    <div *ngIf="welcomeMessage" class="ui segment margin-bottom-2" [innerHtml]="welcomeMessage"></div>
    <video-player *ngIf="video" [video]="video" [responsive]="true" class="margin-bottom-2"></video-player>

    <div class="field" style="text-align: center; margin-bottom: 0">
        <mat-checkbox [(ngModel)]="formData.terms" #terms="ngModel" name="terms" required type="checkbox">
            <span
                [innerHTML]="
                    'forms.setup-welcome.acknowledgeTermsPrivacy'
                        | translate
                            : {
                                  termsOfServiceUrl: 'https://www.humi.ca/terms',
                                  privacyPolicyUrl: 'https://www.humi.ca/privacy',
                              }
                "
            ></span>
        </mat-checkbox>
    </div>
</form>
