import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService } from '@app/services/auth.service';
import { AUTH0_HEADER, TokenService } from '@app/services/token.service';
import * as Sentry from '@sentry/angular';
import { Observable, of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { SKIP_AUTH0_TOKEN } from './context-tokens';
import { shouldSkipInterceptor } from './helpers/shouldSkipInterceptor';

/**
 * This interceptor is the first hit by any requests through Angular's httpClient.
 * It will attempt to attach a token from Auth0 if it already exists, if not, it will retrieve it before making the request.
 */
@Injectable()
export class Auth0Interceptor implements HttpInterceptor {
    constructor(
        private tokenService: TokenService,
        private humiAuthService: AuthService
    ) {}

    intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        // Some requests don't need the Auth0 token, ie. requests for assets such as images and translation json files
        // Additionally, if the user is on local or e2e then we should not reach out to auth0
        if (
            shouldSkipInterceptor(req.url) ||
            req.context.get(SKIP_AUTH0_TOKEN) ||
            !this.humiAuthService.isAuth0AvailableInCurrentEnvironment
        ) {
            return next.handle(req);
        }

        // If the token already exists then simply add it to the request headers and move to the next interceptor
        if (this.tokenService.auth0Token) {
            return this.sendRequestWithToken(req, next);
        }

        // If we don't have the Auth0 token we will send the request to Auth0 to retrieve it before continuing this request
        return this.humiAuthService.retrieveAuth0Token().pipe(
            catchError((error) => {
                Sentry.captureException(error, { level: 'debug', tags: { auth0: true } });
                return of(void 0);
            }),
            switchMap(() => this.sendRequestWithToken(req, next))
        );
    }

    /**
     * Adds the token to the header and sends the request
     */
    private sendRequestWithToken(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        // If for some reason we can't get the Auth0 token we will continue to make the request without the token header, likely resulting in an authentication error from the BE
        if (!this.tokenService.auth0Token) {
            Sentry.captureMessage('Unable to retrieve Auth0 token, continuing request without Auth0token header', {
                level: 'debug',
                tags: { auth0: true },
            });
            return next.handle(req);
        }
        return next.handle(
            req.clone({ headers: req.headers.append(AUTH0_HEADER, `Bearer ${this.tokenService.auth0Token}`) })
        );
    }
}
