import { Injectable, NgZone, Signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { NavigationEnd, Router } from '@angular/router';
import { FeatureFlag } from '@app/enums';
import { HumiInquiryStatus } from '@app/models/kyc-kyb/types';
import { AuthService } from '@app/services';
import { FeatureService } from '@app/services/feature.service';
import { KYCKYBService } from '@app/services/kyc-kyb/kyc-kyb.service';
import { BehaviorSubject, combineLatest, from, Observable, of } from 'rxjs';
import { filter, map, switchMap, takeWhile } from 'rxjs/operators';
import { OnboardingStatusService } from '../self-serve/services/onboarding-status.service';
import { DisplayConditions, KYCKYBDisplayConditions, SelfServeDisplayConditions } from './types';

/**
 * SpecialInformationBarService loads data and sends out events regarding special information.
 * Special Information is information the user should care about, but does not related to a specific
 * section of the application.
 *
 * @see SpecialInformationBar
 */
@Injectable({
    providedIn: 'root',
})
export class SpecialInformationBarService {
    displayConditions$: Observable<DisplayConditions | undefined>;
    displayConditionsSignal: Signal<DisplayConditions | undefined>;

    get specialInformationBarHeight$(): Observable<number> {
        return this._specialInformationBarHeight$.asObservable();
    }

    private specialInformationBarHeightResizeObserver: ResizeObserver;

    private _specialInformationBarHeight$: BehaviorSubject<number> = new BehaviorSubject(0);

    constructor(
        private ngZone: NgZone,
        private authService: AuthService,
        private onboardingStatusService: OnboardingStatusService,
        private knowYourService: KYCKYBService,
        private featureService: FeatureService,
        private router: Router
    ) {
        // prepare the resize observer
        // at this point, it doesn't know what it will observe, but it'll be ready
        this.specialInformationBarHeightResizeObserver = new ResizeObserver((entries) =>
            this.ngZone.run(() =>
                entries.forEach((entry) => this._specialInformationBarHeight$.next(entry.contentRect.height))
            )
        );

        this.displayConditions$ = this.getDisplayConditions();
        this.displayConditionsSignal = toSignal(this.displayConditions$);
    }

    /**
     * observeSpecialInformationBar sets up the observation of the special information bar.
     * This element need to be supplied by the Angular component.
     */
    observeSpecialInformationBar(specialInformationBarNativeElement: HTMLElement): void {
        // remove any previous subscriptions (there should be none)
        this.specialInformationBarHeightResizeObserver.disconnect();

        this.specialInformationBarHeightResizeObserver.observe(specialInformationBarNativeElement);
    }

    private get isAdmin(): boolean {
        return this.authService.isAdmin();
    }

    private get hasPayroll(): boolean {
        return this.authService.hasPayrollAccess;
    }

    /**
     * Retrieves the data required for displaying different special information bars.
     * Additional data can be added here if required for a new special info bar
     */
    private getDisplayConditions(): Observable<DisplayConditions | undefined> {
        return this.authService.onLoginStatusChange.asObservable().pipe(
            switchMap((isLoggedIn) => {
                // Only emit once the user is logged in, otherwise we don't have the data to determine whether the special info bars should display
                if (!isLoggedIn) {
                    return of(undefined);
                }
                return combineLatest([this.getCurrentURL(), this.getOnboardingStatus(), this.getKYCKYBStatus()]).pipe(
                    map(([currentURL, selfServe, KYCKYB]) => {
                        return {
                            isAdmin: this.isAdmin,
                            hasPayroll: this.hasPayroll,
                            currentURL,
                            selfServe,
                            KYCKYB,
                        };
                    })
                );
            })
        );
    }

    /**
     * Gets all observable values related to onboarding
     * @returns null or undefined if not applicable
     */
    private getOnboardingStatus(): Observable<undefined | SelfServeDisplayConditions> {
        // Only admins go through the self-serve quick start onboarding flow
        if (!this.isAdmin) {
            return of(undefined);
        }

        return this.onboardingStatusService.onboardingStatus$.asObservable().pipe(
            map((onboardingStatus) => ({
                onboardingStatus,
                selfServeQuickstart: this.authService.company.selfServeQuickstart,
            }))
        );
    }

    /**
     * Gets all observable values related to Know-Your-Business and Know-Your-Client
     * @returns null or undefined if not applicable
     */
    private getKYCKYBStatus(): Observable<undefined | KYCKYBDisplayConditions> {
        // KYB and KYC are only applicable for users that have access to the payroll module
        if (!this.hasPayroll) {
            return of(undefined);
        }

        // All users with access to payroll need to do KYC
        const personaObservables: Observable<HumiInquiryStatus>[] = [];

        personaObservables.push(
            // Completes the observable when the status is no longer not_complete (we don't need to show the banner any longer)
            this.knowYourService.accountStatus.pipe(takeWhile((status) => status === 'not_complete', true))
        );

        // Only payroll admins need to do KYB
        if (this.isAdmin) {
            personaObservables.push(
                this.knowYourService.companyStatus.pipe(takeWhile((status) => status === 'not_complete', true))
            );
        }

        return from(this.featureService.has(FeatureFlag.KYC_KYB)).pipe(
            switchMap((isFlagEnabled) => {
                if (!isFlagEnabled) {
                    return of(undefined);
                }
                return combineLatest(personaObservables).pipe(
                    map(([KYCStatus, KYBStatus]) => ({
                        hasVerifiedPersonaIdentity: KYCStatus !== 'not_complete',
                        hasVerifiedPersonaBusiness: KYBStatus !== 'not_complete',
                    }))
                );
            })
        );
    }

    private getCurrentURL(): Observable<string> {
        return this.router.events.pipe(
            filter((event) => event instanceof NavigationEnd),
            map((event: NavigationEnd) => event.urlAfterRedirects)
        );
    }
}
