import { DataValue } from '@models/employee/data-value.model';

/**
 * Merge elements of `values` into `baseValues` when there is an ID match
 */
export const mergeDataValuesById = (baseValues: DataValue[], values: DataValue[]): DataValue[] => {
    return baseValues.map((baseValue) => {
        const matchedValue = values.find((value) => value.id === baseValue.id);

        return matchedValue ?? baseValue;
    });
};
