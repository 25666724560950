<div class="page-container h-100" [class.loading]="isLoading">
    @if (!isLoading) {
        <div class="welcome-screen">
            <div class="welcome-text">
                <h1>{{ 'employees.account-activation.welcomeTitle' | translate: { firstName } }}</h1>
                <h3 level="2">{{ 'employees.account-activation.signUpWithEmail' | translate }}</h3>
                <div class="email-container">
                    <input type="text" readonly="readonly" class="email" value="{{ email }}" />
                    <ui-button class="arrow-button" type="outline" title="Confirm Email" (click)="nextStep()">
                        <mat-icon class="arrow" svgIcon="arrowRight" />
                    </ui-button>
                </div>
            </div>
            <div class="welcome-image">
                <img class="logo" src="/assets/illustrations/friends.png" alt="" />
            </div>
        </div>
    }
</div>
