import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { FileHelperService } from '@app/services';
import { IMAGES_ONLY } from '@app/components/platform/file-upload/file-upload.component';

@Component({
    selector: 'app-form-setup-admin',
    templateUrl: './setup-admin.form.html',
})
export class SetupAdminForm implements OnInit, OnDestroy {
    @ViewChild('form', { static: true }) form: NgForm;
    @Input() formData: any = {};
    @Output() valid: EventEmitter<any> = new EventEmitter<any>();

    avatar: File | null = null;
    imagesOnly = IMAGES_ONLY;

    constructor(private fileHelper: FileHelperService) {}

    ngOnInit(): void {
        this.form.statusChanges.subscribe(() => {
            this.valid.emit({ valid: this.form.valid, data: this.formData });
        });
    }

    ngOnDestroy(): void {
        this.valid.emit({ valid: this.form.valid, data: this.formData });
    }

    async saveAvatar(file: File | null): Promise<void> {
        this.avatar = file;
        if (!file) {
            this.formData.avatarId = null;
            return;
        }

        const savedFile = await this.fileHelper.store(file);
        this.formData.avatarId = savedFile.id;
    }
}
