import { APP_INITIALIZER, NgModule } from '@angular/core';
import { SpecialInformationBarService } from './special-information-bar.service';

// Ensures that the service is created upon app creation, allowing for immediate special info banners
@NgModule({
    providers: [
        {
            provide: APP_INITIALIZER,
            // eslint-disable-next-line
            useFactory: () => () => {},
            multi: true,
            deps: [SpecialInformationBarService],
        },
    ],
})
export class SpecialInformationModule {}
