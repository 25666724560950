import { Component, computed, Signal } from '@angular/core';
import { OnboardingStatusService } from '@app/modules/self-serve/services/onboarding-status.service';
import { SpecialInformationBarService } from '@app/modules/special-information/special-information-bar.service';
import { PlatformModule } from '@app/platform.module';
import { AuthService } from '@app/services';
import { TranslateModule } from '@ngx-translate/core';
import * as Sentry from '@sentry/angular';
import { BaseInformationBar } from '../../base-information-type.component';
import { SelfServeTrialDaysRemaining } from '../trial-days-left.component';

/**
 * The webinar banner shown for self-serve clients after they have completed the quickstart process
 */
@Component({
    selector: 'special-info-webinar',
    standalone: true,
    imports: [PlatformModule, TranslateModule, SelfServeTrialDaysRemaining],
    templateUrl: './webinar-info-bar.template.html',
    styleUrls: ['./webinar-info-bar.style.scss'],
})
export class SpecialInfoWebinar extends BaseInformationBar {
    shouldDisplay: Signal<boolean> = computed(() => {
        const { isAdmin, selfServe } = this.displayConditions() || {};
        const { onboardingStatus, selfServeQuickstart } = selfServe || {};

        if (!onboardingStatus) {
            return false;
        }

        if (!(isAdmin && !!selfServeQuickstart)) {
            return false;
        }

        // if the banner has already been dismissed, show nothing
        if (selfServeQuickstart.webinarBannerDismissedAt) {
            return false;
        }

        // only show the banner if the user has completed quickstart
        return !!selfServeQuickstart?.completedAt;
    });

    get trialExpiresAt(): Date | null | undefined {
        return this.displayConditions()?.selfServe?.selfServeQuickstart?.trialExpiresAt;
    }

    constructor(
        private onboardingStatusService: OnboardingStatusService,
        private authService: AuthService,
        specialInformationBarService: SpecialInformationBarService
    ) {
        super(specialInformationBarService);
    }

    /**
     * Dismisses the banner
     */
    hide = async (): Promise<void> => {
        try {
            const { selfServeQuickstart } = this.authService.company;
            selfServeQuickstart.webinarBannerDismissedAt = new Date();
            await selfServeQuickstart.save();
        } catch (error) {
            Sentry.captureException(error);
        } finally {
            this.onboardingStatusService.refresh();
        }
    };
}
