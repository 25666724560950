<app-special-information-bar></app-special-information-bar>

<app-topbar *ngIf="isTopbarVisible"></app-topbar>

<!-- we set the css variable special-information-bar-height for use in other components -->
<div class="ui container" [style]="'--special-information-bar-height: ' + specialInformationBarHeight">
    <app-sidebar *ngIf="isSidebarVisible" #sidebar></app-sidebar>
    <app-dialog-portal />
    <div class="ui outlet" cdkScrollable [class.login]="!authenticated()">
        <div
            *ngIf="showMobileOverlay()"
            @fadeInOut
            class="mobile-menu-overlay only-small"
            (click)="toggleMobileMenu()"
        ></div>

        <mat-sidenav-container>
            <mat-sidenav
                class="loadable"
                [class.loading]="rightNavService.isLoading$ | async"
                mode="side"
                [opened]="rightNavService.isOpen$ | async"
                position="end"
                role="navigation"
                [attr.aria-label]="'screen-reader-only.rightNavigation' | translate"
            >
                <ng-container *ngComponentOutlet="currentSideNavComponent"></ng-container>
            </mat-sidenav>
            <mat-sidenav-content [class.with-top-bar]="isTopbarVisible"
                ><router-outlet (activate)="onRouteActivate($event, outlet)" #outlet> </router-outlet>
            </mat-sidenav-content>
        </mat-sidenav-container>
        <ng-container *ngIf="!toggleSetupGuide"
            ><button
                *ngIf="rightNavService.isVisible$ | async"
                (click)="
                    rightNavService.open();
                    rightNavService.removePersistClose();
                    trackSetupGuideOpened();
                    state = 'default'
                "
                class="setup-guide-button"
                [@buttonAnimation]="state"
                (mouseenter)="state = 'hover'"
                (mouseleave)="state = 'default'"
                mat-raised-button
                mat-mini-fab
                color="white"
            >
                <span *ngIf="state === 'default'"><mat-icon class="svg-bullet" svgIcon="listBulleted"></mat-icon></span>
                <span *ngIf="state === 'hover'" class="flex align-items-center"
                    ><mat-icon color="primary" class="margin-right-1" svgIcon="listBulleted"></mat-icon
                    >{{ 'setupGuide' | translate }}</span
                >
            </button>
        </ng-container>

        <ui-async-system-tasks-tray></ui-async-system-tasks-tray>

        <div class="ui active inverted dimmer app-loader">
            <div class="ui loader"></div>
        </div>

        <app-zen-desk></app-zen-desk>

        <div class="env-bar" *ngIf="env.name !== 'production'">
            <ng-container [ngSwitch]="env.name">
                <ng-container *ngSwitchCase="'demo'">Demo Environment</ng-container>
                <ng-container *ngSwitchCase="'qa'">QA Environment</ng-container>
                <ng-container *ngSwitchCase="'e2e'">E2E Environment</ng-container>
                <ng-container *ngSwitchCase="'local'"
                    ><marquee>
                        <span *ngFor="let i of [1, 2, 3, 4, 5]" style="margin-right: 150px"
                            >(ﾉ◕ヮ◕)ﾉ*:･ﾟ✧ Development Environment &nbsp; ┬┴┬┴┤･ω･)ﾉ
                        </span>
                    </marquee></ng-container
                >
                <ng-container *ngSwitchCase="'sec'">Security Environment</ng-container>
            </ng-container>
        </div>
    </div>
</div>
