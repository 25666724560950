import { Component } from '@angular/core';
import { AuthService } from '@app/services';

@Component({
    templateUrl: './activate-error.template.html',
})
export class ActivateErrorView {
    token: string | null;

    constructor(private authService: AuthService) {
        this.token = this.authService.activationToken;
    }
}
