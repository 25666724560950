import { CdkPortalOutlet, PortalOutlet, TemplatePortal } from '@angular/cdk/portal';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { DialogService } from '@app/services';
import { DialogConfig } from '@app/services/dialog.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'app-dialog-portal',
    templateUrl: './dialog-portal.view.html',
})
export class DialogPortalView implements OnInit, OnDestroy {
    @ViewChild(CdkPortalOutlet, { static: false }) portalOutlet!: PortalOutlet;
    dialogConfig: DialogConfig = { open: false, header: '' };

    private _destroyed: Subject<null> = new Subject();

    constructor(private dialogService: DialogService) {}

    ngOnInit(): void {
        this.dialogService.state.pipe(takeUntil(this._destroyed)).subscribe((config) => (this.dialogConfig = config));

        this.dialogService.portal.pipe(takeUntil(this._destroyed)).subscribe((portal: TemplatePortal | undefined) => {
            if (portal) {
                this.portalOutlet.detach();
                this.portalOutlet.attach(portal);
            }
        });
    }

    ngOnDestroy(): void {
        this._destroyed.next();
    }
}
