import { Type } from '@angular/core';
import { PlatformModules } from '@app/classes';
import { FeatureFlag } from '@app/enums';
import { TimeOffSideNavComponent } from '@app/modules/time-off-v3/standalone-components';

// This is slightly better than using any since it will require a type assertion to use any method or property on the component
export type AngularComponentType<T = unknown> = Type<T>;

export type SetupGuideConfig = {
    path: string;
    component: AngularComponentType;
    showIfFeatureFlag?: FeatureFlag;
    isOpen: boolean;
    isAdmin: boolean;
};

export const setupGuidesMeta: Partial<{
    [key in PlatformModules]: SetupGuideConfig;
}> = {
    'Time Off': {
        path: 'time-off/v3',
        component: TimeOffSideNavComponent,
        showIfFeatureFlag: FeatureFlag.timeOffSelfServe,
        isOpen: true,
        isAdmin: true,
    },
};
