import { Injectable } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { ViewportOptions } from '@app/interfaces';

const VIEWPORT = {
    RESPONSIVE: {
        name: 'viewport',
        content: 'width=device-width, initial-scale=1.0, maximum-scale = 1.0, user-scalable = no',
    },
    NOT_RESPONSIVE: { name: 'viewport', content: 'width=1280' },
} as const;

@Injectable({ providedIn: 'root' })
export class ViewportService {
    // Signifies whether the viewport has been set immediately after first load of the application (typically login)
    private _isInitialized = false;

    get isInitialized(): boolean {
        return this._isInitialized;
    }

    constructor(private meta: Meta) {}

    initialize(): void {
        this._isInitialized = true;
    }

    async updateViewport(viewport: ViewportOptions): Promise<void> {
        if (!this.isInitialized) {
            this.initialize();
        }

        if (viewport.disableResponsiveView) {
            this.meta.updateTag(VIEWPORT.NOT_RESPONSIVE);
        } else {
            this.meta.updateTag(VIEWPORT.RESPONSIVE);
        }
    }
}
