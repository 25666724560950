import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { AuthService } from '@app/services/auth.service';
import { HireAnswer } from '@models/employee/hire-answer.model';
import { HireQuestion } from '@models/employee/hire-question.model';

@Component({
    selector: 'app-form-setup-survey',
    templateUrl: './setup-survey.form.html',
    styleUrls: ['./setup-survey.responsive.scss'],
})
export class SetupSurveyForm implements OnInit, OnDestroy {
    @ViewChild('form', { static: true }) form: NgForm;
    @Input() formData: any = {};
    @Output() valid: EventEmitter<any> = new EventEmitter<any>();
    isLoading = true;
    isValid = false;
    hireQuestions: HireQuestion[] = [];

    constructor(private auth: AuthService) {}

    ngOnInit(): void {
        this.form.statusChanges.subscribe(() => {
            this.isValid = this.form.valid;
            this.valid.emit({ valid: this.form.valid, data: this.formData });
        });

        HireQuestion.param('company', this.auth.company.id)
            .all()
            .then(([onboardingQuestions]) => {
                this.hireQuestions = onboardingQuestions;
                for (const question of this.hireQuestions) {
                    this.formData['surveyAnswer' + question.id] = new HireAnswer({
                        questionId: question.id,
                        answer: null,
                    })
                        .param('company', this.auth.company.id)
                        .param('employee', this.auth.employee.id)
                        .param('hireQuestion', question.id);
                }
                this.isLoading = false;
            });
    }

    submit(): void {
        this.form.onSubmit(new Event('submit'));
        this.valid.emit({ valid: this.form.valid, data: this.formData });
        this.isValid = this.form.valid;
    }

    ngOnDestroy(): void {
        this.valid.emit({ valid: this.form.valid, data: this.formData });
    }
}
