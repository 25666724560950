<!-- Modals -->
<app-pdf-viewer-dialog
    [open]="pdfViewerOpen"
    (closing)="pdfViewerOpen = false"
    (buttonClicked)="onAcknowledge()"
    [buttonLabel]="'forms.setup-documents.acknowledgeButtonLabel' | translate"
    #pdfViewer
></app-pdf-viewer-dialog>

<app-image-acknowledger #imageViewer (buttonClicked)="onAcknowledge()"></app-image-acknowledger>

<!-- Body -->
<form class="ui form" #form="ngForm" autocomplete="off" [class.loading]="isLoading">
    <h1 class="ui header">{{ 'forms.setup-documents.header' | translate }}</h1>

    <table class="ui selectable table">
        <tr
            *ngFor="let document of employeeDocuments"
            [class.incomplete]="!document.isComplete()"
            (click)="onSign(document)"
        >
            <ng-container *ngIf="document.isActionable(); else viewOnlyDoc">
                <td>
                    <div class="flex align-middle mid">
                        <mat-icon svgIcon="documents"></mat-icon>&nbsp;&nbsp;{{ document.name }}
                    </div>
                </td>
                <td class="right aligned">
                    <span *ngIf="!document.isComplete()">
                        <i class="red dot"></i>{{ 'forms.setup-documents.incompleteLabel' | translate }}
                    </span>
                    <span *ngIf="document.isComplete()">
                        <i class="green dot"></i>{{ 'forms.setup-documents.completeLabel' | translate }}
                    </span>
                </td>
            </ng-container>
            <ng-template #viewOnlyDoc>
                <td>
                    <div class="flex align-middle mid">
                        <mat-icon svgIcon="documents"></mat-icon>&nbsp;&nbsp;{{ document.name }}
                    </div>
                </td>
                <td class="right aligned"></td>
            </ng-template>
        </tr>
    </table>

    <div class="ui segment" *ngIf="!employeeDocuments?.length">
        <mat-icon svgIcon="info"></mat-icon>&nbsp;&nbsp;&nbsp;{{
            'forms.setup-documents.noDocumentsMessage' | translate
        }}
    </div>
</form>
