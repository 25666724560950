const SKIP_INTERCEPTOR_URLS = [
    '/assets/', // Assets are retrieved locally therefore they don't need authentication headers
] as const;

/**
 * Check disallowed URLs list (ie. translation assets, etc.) that don't need to be run through our interceptors.
 * This is often due to us adding headers that are not necessary to the requests.
 */
export const shouldSkipInterceptor = (requestUrl: string): boolean =>
    SKIP_INTERCEPTOR_URLS.some((url) => requestUrl.startsWith(url));
